// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.square[data-v-857ba246] {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  border: 6pt solid;
  border-color: #000;
}
.squareRadius[data-v-857ba246] {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  border: 6pt solid #000;
  border-color: #000;
  border-radius: 30px;
}
.back[data-v-857ba246] {
  width: 100%;
  height: 100%;
}
.circle[data-v-857ba246] {
  border-radius: 50%;
  border: 6pt solid;
  border-color: #000;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
