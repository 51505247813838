const flightsData = {
        arrivals: [
          {
            aircrafttype: "",
            ident: "BT 680",
            airline: "Air Baltic",
            destinationIata: "RIX",
            originName: "Lisbon Airport",
            destinationName: "Riga International Airport",
            originCity: "Lisbon",
            destinationCity: "Riga",
            originIata: "LIS",
            departuretime: 1689586200,
            arrivaltime: 0,
            status: "Delayed",
            gate: "",
            terminal: "",
            ident_icao: "BT "
          },
          {
            aircrafttype: "",
            ident: "TO 7851",
            airline: "Transavia France",
            destinationIata: "BES",
            originName: "Porto Airport",
            destinationName: "Brest Bretagne Airport",
            originCity: "Porto",
            destinationCity: "Brest",
            originIata: "OPO",
            departuretime: 1689586200,
            arrivaltime: 0,
            status: "Departed",
            gate: "",
            terminal: "",
            ident_icao: "TO "
          },
          {
            aircrafttype: "",
            ident: "QS 2164",
            airline: "SmartWings",
            destinationIata: "DSS",
            originName: "Porto Airport",
            destinationName: "Blaise Diagne International Airport",
            originCity: "Porto",
            destinationCity: "Dakar",
            originIata: "OPO",
            departuretime: 1689586500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "QS "
          },
          {
            aircrafttype: "",
            ident: "FR 1387",
            airline: "Ryanair",
            destinationIata: "CRL",
            originName: "Porto Airport",
            destinationName: "Brussels S. Charleroi Airport",
            originCity: "Porto",
            destinationCity: "Brussels",
            originIata: "OPO",
            departuretime: 1689588300,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "LH 1783",
            airline: "Lufthansa",
            destinationIata: "MUC",
            originName: "Porto Airport",
            destinationName: "Franz Josef Strauss Airport",
            originCity: "Porto",
            destinationCity: "Munich",
            originIata: "OPO",
            departuretime: 1689588600,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "LH "
          },
          {
            aircrafttype: "",
            ident: "TP 922",
            airline: "TAP Air Portugal",
            destinationIata: "ZRH",
            originName: "Porto Airport",
            destinationName: "Zurich Airport",
            originCity: "Porto",
            destinationCity: "Zurich",
            originIata: "OPO",
            departuretime: 1689588900,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TP "
          },
          {
            aircrafttype: "",
            ident: "KL 1712",
            airline: "KLM",
            destinationIata: "AMS",
            originName: "Porto Airport",
            destinationName: "Amsterdam Airport Schiphol",
            originCity: "Porto",
            destinationCity: "Amsterdam",
            originIata: "OPO",
            departuretime: 1689589500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "KL "
          },
          {
            aircrafttype: "",
            ident: "TK 1450",
            airline: "Turkish Airlines",
            destinationIata: "ISL",
            originName: "Porto Airport",
            destinationName: "Istanbul Airport",
            originCity: "Porto",
            destinationCity: "Istanbul",
            originIata: "OPO",
            departuretime: 1689591900,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TK "
          },
          {
            aircrafttype: "",
            ident: "U2 4760",
            airline: "easyJet",
            destinationIata: "NTE",
            originName: "Porto Airport",
            destinationName: "Nantes Atlantique Airport",
            originCity: "Porto",
            destinationCity: "Nantes",
            originIata: "OPO",
            departuretime: 1689593400,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "UA 145",
            airline: "United Airlines",
            destinationIata: "EWR",
            originName: "Porto Airport",
            destinationName: "Newark Liberty International Airport",
            originCity: "Porto",
            destinationCity: "Newark",
            originIata: "OPO",
            departuretime: 1689593700,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "11",
            terminal: "",
            ident_icao: "UA "
          },
          {
            aircrafttype: "",
            ident: "U2 7713",
            airline: "easyJet",
            destinationIata: "BOD",
            originName: "Porto Airport",
            destinationName: "Bordeaux Airport",
            originCity: "Porto",
            destinationCity: "Bordeaux",
            originIata: "OPO",
            departuretime: 1689594300,
            arrivaltime: 0,
            status: "Delayed",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "FR 7472",
            airline: "Ryanair",
            destinationIata: "EIN",
            originName: "Porto Airport",
            destinationName: "Eindhoven Airport",
            originCity: "Porto",
            destinationCity: "Eindhoven",
            originIata: "OPO",
            departuretime: 1689594900,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "LX 2067",
            airline: "SWISS",
            destinationIata: "ZRH",
            originName: "Porto Airport",
            destinationName: "Zurich Airport",
            originCity: "Porto",
            destinationCity: "Zurich",
            originIata: "OPO",
            departuretime: 1689595200,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "LX "
          },
          {
            aircrafttype: "",
            ident: "FR 4564",
            airline: "Ryanair",
            destinationIata: "XCR",
            originName: "Porto Airport",
            destinationName: "Chalons Vatry Airport",
            originCity: "Porto",
            destinationCity: "Chalons-en-Champagne",
            originIata: "OPO",
            departuretime: 1689595500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "U2 7721",
            airline: "easyJet",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689596700,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "TS 765",
            airline: "Air Transat",
            destinationIata: "YYZ",
            originName: "Porto Airport",
            destinationName: "Pearson International Airport",
            originCity: "Porto",
            destinationCity: "Toronto",
            originIata: "OPO",
            departuretime: 1689597000,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TS "
          },
          {
            aircrafttype: "",
            ident: "FR 4546",
            airline: "Ryanair",
            destinationIata: "BCN",
            originName: "Porto Airport",
            destinationName: "Barcelona-El Prat Airport",
            originCity: "Porto",
            destinationCity: "Barcelona",
            originIata: "OPO",
            departuretime: 1689598200,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "TO 7603",
            airline: "Transavia France",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689598200,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TO "
          },
          {
            aircrafttype: "",
            ident: "TP 456",
            airline: "TAP Air Portugal",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689598800,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TP "
          },
          {
            aircrafttype: "",
            ident: "FR 3259",
            airline: "Ryanair",
            destinationIata: "TRN",
            originName: "Porto Airport",
            destinationName: "Turin Airport",
            originCity: "Porto",
            destinationCity: "Turin",
            originIata: "OPO",
            departuretime: 1689599100,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "VY 1986",
            airline: "Vueling",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689599400,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "VY "
          },
          {
            aircrafttype: "",
            ident: "U2 4430",
            airline: "easyJet",
            destinationIata: "LYS",
            originName: "Porto Airport",
            destinationName: "Lyon Saint-Exupery International Airport",
            originCity: "Porto",
            destinationCity: "Lyon",
            originIata: "OPO",
            departuretime: 1689599820,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "TO 7605",
            airline: "Transavia France",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689600000,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TO "
          },
          {
            aircrafttype: "",
            ident: "FR 5256",
            airline: "Ryanair",
            destinationIata: "BVE",
            originName: "Porto Airport",
            destinationName: "Vallee de la Dordogne Airport",
            originCity: "Porto",
            destinationCity: "Brive-La-Gaillarde",
            originIata: "OPO",
            departuretime: 1689600900,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "TP 686",
            airline: "TAP Air Portugal",
            destinationIata: "LUX",
            originName: "Porto Airport",
            destinationName: "Luxembourg Airport",
            originCity: "Porto",
            destinationCity: "Luxembourg",
            originIata: "OPO",
            departuretime: 1689603300,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TP "
          },
          {
            aircrafttype: "",
            ident: "TO 7613",
            airline: "Transavia France",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689603600,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TO "
          },
          {
            aircrafttype: "",
            ident: "BJ 6451",
            airline: "Nouvelair Tunisie",
            destinationIata: "DJE",
            originName: "Porto Airport",
            destinationName: "Zarsis Airport",
            originCity: "Porto",
            destinationCity: "Djerba",
            originIata: "OPO",
            departuretime: 1689604500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "BJ "
          },
          {
            aircrafttype: "",
            ident: "FR 4422",
            airline: "Ryanair",
            destinationIata: "WRO",
            originName: "Porto Airport",
            destinationName: "Wroclaw Airport",
            originCity: "Porto",
            destinationCity: "Wroclaw",
            originIata: "OPO",
            departuretime: 1689604500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "TP 1949",
            airline: "TAP Air Portugal",
            destinationIata: "LIS",
            originName: "Porto Airport",
            destinationName: "Lisbon Portela Airport",
            originCity: "Porto",
            destinationCity: "Lisbon",
            originIata: "OPO",
            departuretime: 1689606000,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TP "
          },
          {
            aircrafttype: "",
            ident: "AF 1529",
            airline: "Air France",
            destinationIata: "CDG",
            originName: "Porto Airport",
            destinationName: "Charles de Gaulle Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689607200,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "AF "
          },
          {
            aircrafttype: "",
            ident: "U2 1874",
            airline: "easyJet",
            destinationIata: "BOD",
            originName: "Porto Airport",
            destinationName: "Bordeaux Airport",
            originCity: "Porto",
            destinationCity: "Bordeaux",
            originIata: "OPO",
            departuretime: 1689607260,
            arrivaltime: 0,
            status: "Delayed",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "FR 7699",
            airline: "Ryanair",
            destinationIata: "STN",
            originName: "Porto Airport",
            destinationName: "Stansted Airport",
            originCity: "Porto",
            destinationCity: "London",
            originIata: "OPO",
            departuretime: 1689607500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "KL 1714",
            airline: "KLM",
            destinationIata: "AMS",
            originName: "Porto Airport",
            destinationName: "Amsterdam Airport Schiphol",
            originCity: "Porto",
            destinationCity: "Amsterdam",
            originIata: "OPO",
            departuretime: 1689607500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "KL "
          },
          {
            aircrafttype: "",
            ident: "S4 6473",
            airline: "Azores Airlines",
            destinationIata: "PXO",
            originName: "Porto Airport",
            destinationName: "Porto Santo Airport",
            originCity: "Porto",
            destinationCity: "Porto Santo (Madeira)",
            originIata: "OPO",
            departuretime: 1689607500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "S4 "
          },
          {
            aircrafttype: "",
            ident: "LH 1179",
            airline: "Lufthansa",
            destinationIata: "FRA",
            originName: "Porto Airport",
            destinationName: "Frankfurt Airport",
            originCity: "Porto",
            destinationCity: "Frankfurt",
            originIata: "OPO",
            departuretime: 1689607800,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "LH "
          },
          {
            aircrafttype: "",
            ident: "IB 3095",
            airline: "Iberia",
            destinationIata: "MAD",
            originName: "Porto Airport",
            destinationName: "Adolfo Suarez Madrid-Barajas Airport",
            originCity: "Porto",
            destinationCity: "Madrid",
            originIata: "OPO",
            departuretime: 1689608100,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "IB "
          },
          {
            aircrafttype: "",
            ident: "VY 8479",
            airline: "Vueling",
            destinationIata: "BCN",
            originName: "Porto Airport",
            destinationName: "Barcelona-El Prat Airport",
            originCity: "Porto",
            destinationCity: "Barcelona",
            originIata: "OPO",
            departuretime: 1689608100,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "VY "
          },
          {
            aircrafttype: "",
            ident: "TK 1452",
            airline: "Turkish Airlines",
            destinationIata: "ISL",
            originName: "Porto Airport",
            destinationName: "Istanbul Airport",
            originCity: "Porto",
            destinationCity: "Istanbul",
            originIata: "OPO",
            departuretime: 1689608400,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TK "
          },
          {
            aircrafttype: "",
            ident: "FR 1174",
            airline: "Ryanair",
            destinationIata: "MAN",
            originName: "Porto Airport",
            destinationName: "Manchester Airport",
            originCity: "Porto",
            destinationCity: "Manchester",
            originIata: "OPO",
            departuretime: 1689609300,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "FR 4017",
            airline: "Ryanair",
            destinationIata: "MXP",
            originName: "Porto Airport",
            destinationName: "Milano Malpensa Airport",
            originCity: "Porto",
            destinationCity: "Milan",
            originIata: "OPO",
            departuretime: 1689609600,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "SN 3810",
            airline: "Brussels Airlines",
            destinationIata: "BRU",
            originName: "Porto Airport",
            destinationName: "Brussels Airport",
            originCity: "Porto",
            destinationCity: "Brussels",
            originIata: "OPO",
            departuretime: 1689609600,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "SN "
          },
          {
            aircrafttype: "",
            ident: "U2 7781",
            airline: "easyJet",
            destinationIata: "MXP",
            originName: "Porto Airport",
            destinationName: "Milano Malpensa Airport",
            originCity: "Porto",
            destinationCity: "Milan",
            originIata: "OPO",
            departuretime: 1689609600,
            arrivaltime: 0,
            status: "Delayed",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "FR 2865",
            airline: "Ryanair",
            destinationIata: "DTM",
            originName: "Porto Airport",
            destinationName: "Dortmund Airport",
            originCity: "Porto",
            destinationCity: "Dortmund",
            originIata: "OPO",
            departuretime: 1689610500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "U2 7743",
            airline: "easyJet",
            destinationIata: "ZRH",
            originName: "Porto Airport",
            destinationName: "Zurich Airport",
            originCity: "Porto",
            destinationCity: "Zurich",
            originIata: "OPO",
            departuretime: 1689610500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "FR 4584",
            airline: "Ryanair",
            destinationIata: "BCN",
            originName: "Porto Airport",
            destinationName: "Barcelona-El Prat Airport",
            originCity: "Porto",
            destinationCity: "Barcelona",
            originIata: "OPO",
            departuretime: 1689611100,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          }
        ],
        departures: [
          {
            aircrafttype: "",
            ident: "BT 680",
            airline: "Air Baltic",
            destinationIata: "RIX",
            originName: "Porto Airport",
            destinationName: "Riga International Airport",
            originCity: "Porto",
            destinationCity: "Riga",
            originIata: "OPO",
            departuretime: 1689586200,
            arrivaltime: 0,
            status: "Delayed",
            gate: "",
            terminal: "",
            ident_icao: "BT "
          },
          {
            aircrafttype: "",
            ident: "TO 7851",
            airline: "Transavia France",
            destinationIata: "BES",
            originName: "Porto Airport",
            destinationName: "Brest Bretagne Airport",
            originCity: "Porto",
            destinationCity: "Brest",
            originIata: "OPO",
            departuretime: 1689586200,
            arrivaltime: 0,
            status: "Departed",
            gate: "",
            terminal: "",
            ident_icao: "TO "
          },
          {
            aircrafttype: "",
            ident: "QS 2164",
            airline: "SmartWings",
            destinationIata: "DSS",
            originName: "Porto Airport",
            destinationName: "Blaise Diagne International Airport",
            originCity: "Porto",
            destinationCity: "Dakar",
            originIata: "OPO",
            departuretime: 1689586500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "QS "
          },
          {
            aircrafttype: "",
            ident: "FR 1387",
            airline: "Ryanair",
            destinationIata: "CRL",
            originName: "Porto Airport",
            destinationName: "Brussels S. Charleroi Airport",
            originCity: "Porto",
            destinationCity: "Brussels",
            originIata: "OPO",
            departuretime: 1689588300,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "LH 1783",
            airline: "Lufthansa",
            destinationIata: "MUC",
            originName: "Porto Airport",
            destinationName: "Franz Josef Strauss Airport",
            originCity: "Porto",
            destinationCity: "Munich",
            originIata: "OPO",
            departuretime: 1689588600,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "LH "
          },
          {
            aircrafttype: "",
            ident: "TP 922",
            airline: "TAP Air Portugal",
            destinationIata: "ZRH",
            originName: "Porto Airport",
            destinationName: "Zurich Airport",
            originCity: "Porto",
            destinationCity: "Zurich",
            originIata: "OPO",
            departuretime: 1689588900,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TP "
          },
          {
            aircrafttype: "",
            ident: "KL 1712",
            airline: "KLM",
            destinationIata: "AMS",
            originName: "Porto Airport",
            destinationName: "Amsterdam Airport Schiphol",
            originCity: "Porto",
            destinationCity: "Amsterdam",
            originIata: "OPO",
            departuretime: 1689589500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "KL "
          },
          {
            aircrafttype: "",
            ident: "TK 1450",
            airline: "Turkish Airlines",
            destinationIata: "ISL",
            originName: "Porto Airport",
            destinationName: "Istanbul Airport",
            originCity: "Porto",
            destinationCity: "Istanbul",
            originIata: "OPO",
            departuretime: 1689591900,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TK "
          },
          {
            aircrafttype: "",
            ident: "U2 4760",
            airline: "easyJet",
            destinationIata: "NTE",
            originName: "Porto Airport",
            destinationName: "Nantes Atlantique Airport",
            originCity: "Porto",
            destinationCity: "Nantes",
            originIata: "OPO",
            departuretime: 1689593400,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "UA 145",
            airline: "United Airlines",
            destinationIata: "EWR",
            originName: "Porto Airport",
            destinationName: "Newark Liberty International Airport",
            originCity: "Porto",
            destinationCity: "Newark",
            originIata: "OPO",
            departuretime: 1689593700,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "11",
            terminal: "",
            ident_icao: "UA "
          },
          {
            aircrafttype: "",
            ident: "U2 7713",
            airline: "easyJet",
            destinationIata: "BOD",
            originName: "Porto Airport",
            destinationName: "Bordeaux Airport",
            originCity: "Porto",
            destinationCity: "Bordeaux",
            originIata: "OPO",
            departuretime: 1689594300,
            arrivaltime: 0,
            status: "Delayed",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "FR 7472",
            airline: "Ryanair",
            destinationIata: "EIN",
            originName: "Porto Airport",
            destinationName: "Eindhoven Airport",
            originCity: "Porto",
            destinationCity: "Eindhoven",
            originIata: "OPO",
            departuretime: 1689594900,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "LX 2067",
            airline: "SWISS",
            destinationIata: "ZRH",
            originName: "Porto Airport",
            destinationName: "Zurich Airport",
            originCity: "Porto",
            destinationCity: "Zurich",
            originIata: "OPO",
            departuretime: 1689595200,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "LX "
          },
          {
            aircrafttype: "",
            ident: "FR 4564",
            airline: "Ryanair",
            destinationIata: "XCR",
            originName: "Porto Airport",
            destinationName: "Chalons Vatry Airport",
            originCity: "Porto",
            destinationCity: "Chalons-en-Champagne",
            originIata: "OPO",
            departuretime: 1689595500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "U2 7721",
            airline: "easyJet",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689596700,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "TS 765",
            airline: "Air Transat",
            destinationIata: "YYZ",
            originName: "Porto Airport",
            destinationName: "Pearson International Airport",
            originCity: "Porto",
            destinationCity: "Toronto",
            originIata: "OPO",
            departuretime: 1689597000,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TS "
          },
          {
            aircrafttype: "",
            ident: "FR 4546",
            airline: "Ryanair",
            destinationIata: "BCN",
            originName: "Porto Airport",
            destinationName: "Barcelona-El Prat Airport",
            originCity: "Porto",
            destinationCity: "Barcelona",
            originIata: "OPO",
            departuretime: 1689598200,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "TO 7603",
            airline: "Transavia France",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689598200,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TO "
          },
          {
            aircrafttype: "",
            ident: "TP 456",
            airline: "TAP Air Portugal",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689598800,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TP "
          },
          {
            aircrafttype: "",
            ident: "FR 3259",
            airline: "Ryanair",
            destinationIata: "TRN",
            originName: "Porto Airport",
            destinationName: "Turin Airport",
            originCity: "Porto",
            destinationCity: "Turin",
            originIata: "OPO",
            departuretime: 1689599100,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "VY 1986",
            airline: "Vueling",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689599400,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "VY "
          },
          {
            aircrafttype: "",
            ident: "U2 4430",
            airline: "easyJet",
            destinationIata: "LYS",
            originName: "Porto Airport",
            destinationName: "Lyon Saint-Exupery International Airport",
            originCity: "Porto",
            destinationCity: "Lyon",
            originIata: "OPO",
            departuretime: 1689599820,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "TO 7605",
            airline: "Transavia France",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689600000,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TO "
          },
          {
            aircrafttype: "",
            ident: "FR 5256",
            airline: "Ryanair",
            destinationIata: "BVE",
            originName: "Porto Airport",
            destinationName: "Vallee de la Dordogne Airport",
            originCity: "Porto",
            destinationCity: "Brive-La-Gaillarde",
            originIata: "OPO",
            departuretime: 1689600900,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "TP 686",
            airline: "TAP Air Portugal",
            destinationIata: "LUX",
            originName: "Porto Airport",
            destinationName: "Luxembourg Airport",
            originCity: "Porto",
            destinationCity: "Luxembourg",
            originIata: "OPO",
            departuretime: 1689603300,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TP "
          },
          {
            aircrafttype: "",
            ident: "TO 7613",
            airline: "Transavia France",
            destinationIata: "ORY",
            originName: "Porto Airport",
            destinationName: "Paris Orly Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689603600,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TO "
          },
          {
            aircrafttype: "",
            ident: "BJ 6451",
            airline: "Nouvelair Tunisie",
            destinationIata: "DJE",
            originName: "Porto Airport",
            destinationName: "Zarsis Airport",
            originCity: "Porto",
            destinationCity: "Djerba",
            originIata: "OPO",
            departuretime: 1689604500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "BJ "
          },
          {
            aircrafttype: "",
            ident: "FR 4422",
            airline: "Ryanair",
            destinationIata: "WRO",
            originName: "Porto Airport",
            destinationName: "Wroclaw Airport",
            originCity: "Porto",
            destinationCity: "Wroclaw",
            originIata: "OPO",
            departuretime: 1689604500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "TP 1949",
            airline: "TAP Air Portugal",
            destinationIata: "LIS",
            originName: "Porto Airport",
            destinationName: "Lisbon Portela Airport",
            originCity: "Porto",
            destinationCity: "Lisbon",
            originIata: "OPO",
            departuretime: 1689606000,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TP "
          },
          {
            aircrafttype: "",
            ident: "AF 1529",
            airline: "Air France",
            destinationIata: "CDG",
            originName: "Porto Airport",
            destinationName: "Charles de Gaulle Airport",
            originCity: "Porto",
            destinationCity: "Paris",
            originIata: "OPO",
            departuretime: 1689607200,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "AF "
          },
          {
            aircrafttype: "",
            ident: "U2 1874",
            airline: "easyJet",
            destinationIata: "BOD",
            originName: "Porto Airport",
            destinationName: "Bordeaux Airport",
            originCity: "Porto",
            destinationCity: "Bordeaux",
            originIata: "OPO",
            departuretime: 1689607260,
            arrivaltime: 0,
            status: "Delayed",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "FR 7699",
            airline: "Ryanair",
            destinationIata: "STN",
            originName: "Porto Airport",
            destinationName: "Stansted Airport",
            originCity: "Porto",
            destinationCity: "London",
            originIata: "OPO",
            departuretime: 1689607500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "KL 1714",
            airline: "KLM",
            destinationIata: "AMS",
            originName: "Porto Airport",
            destinationName: "Amsterdam Airport Schiphol",
            originCity: "Porto",
            destinationCity: "Amsterdam",
            originIata: "OPO",
            departuretime: 1689607500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "KL "
          },
          {
            aircrafttype: "",
            ident: "S4 6473",
            airline: "Azores Airlines",
            destinationIata: "PXO",
            originName: "Porto Airport",
            destinationName: "Porto Santo Airport",
            originCity: "Porto",
            destinationCity: "Porto Santo (Madeira)",
            originIata: "OPO",
            departuretime: 1689607500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "S4 "
          },
          {
            aircrafttype: "",
            ident: "LH 1179",
            airline: "Lufthansa",
            destinationIata: "FRA",
            originName: "Porto Airport",
            destinationName: "Frankfurt Airport",
            originCity: "Porto",
            destinationCity: "Frankfurt",
            originIata: "OPO",
            departuretime: 1689607800,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "LH "
          },
          {
            aircrafttype: "",
            ident: "IB 3095",
            airline: "Iberia",
            destinationIata: "MAD",
            originName: "Porto Airport",
            destinationName: "Adolfo Suarez Madrid-Barajas Airport",
            originCity: "Porto",
            destinationCity: "Madrid",
            originIata: "OPO",
            departuretime: 1689608100,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "IB "
          },
          {
            aircrafttype: "",
            ident: "VY 8479",
            airline: "Vueling",
            destinationIata: "BCN",
            originName: "Porto Airport",
            destinationName: "Barcelona-El Prat Airport",
            originCity: "Porto",
            destinationCity: "Barcelona",
            originIata: "OPO",
            departuretime: 1689608100,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "VY "
          },
          {
            aircrafttype: "",
            ident: "TK 1452",
            airline: "Turkish Airlines",
            destinationIata: "ISL",
            originName: "Porto Airport",
            destinationName: "Istanbul Airport",
            originCity: "Porto",
            destinationCity: "Istanbul",
            originIata: "OPO",
            departuretime: 1689608400,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "TK "
          },
          {
            aircrafttype: "",
            ident: "FR 1174",
            airline: "Ryanair",
            destinationIata: "MAN",
            originName: "Porto Airport",
            destinationName: "Manchester Airport",
            originCity: "Porto",
            destinationCity: "Manchester",
            originIata: "OPO",
            departuretime: 1689609300,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "FR 4017",
            airline: "Ryanair",
            destinationIata: "MXP",
            originName: "Porto Airport",
            destinationName: "Milano Malpensa Airport",
            originCity: "Porto",
            destinationCity: "Milan",
            originIata: "OPO",
            departuretime: 1689609600,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "SN 3810",
            airline: "Brussels Airlines",
            destinationIata: "BRU",
            originName: "Porto Airport",
            destinationName: "Brussels Airport",
            originCity: "Porto",
            destinationCity: "Brussels",
            originIata: "OPO",
            departuretime: 1689609600,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "SN "
          },
          {
            aircrafttype: "",
            ident: "U2 7781",
            airline: "easyJet",
            destinationIata: "MXP",
            originName: "Porto Airport",
            destinationName: "Milano Malpensa Airport",
            originCity: "Porto",
            destinationCity: "Milan",
            originIata: "OPO",
            departuretime: 1689609600,
            arrivaltime: 0,
            status: "Delayed",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "FR 2865",
            airline: "Ryanair",
            destinationIata: "DTM",
            originName: "Porto Airport",
            destinationName: "Dortmund Airport",
            originCity: "Porto",
            destinationCity: "Dortmund",
            originIata: "OPO",
            departuretime: 1689610500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          },
          {
            aircrafttype: "",
            ident: "U2 7743",
            airline: "easyJet",
            destinationIata: "ZRH",
            originName: "Porto Airport",
            destinationName: "Zurich Airport",
            originCity: "Porto",
            destinationCity: "Zurich",
            originIata: "OPO",
            departuretime: 1689610500,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "U2 "
          },
          {
            aircrafttype: "",
            ident: "FR 4584",
            airline: "Ryanair",
            destinationIata: "BCN",
            originName: "Porto Airport",
            destinationName: "Barcelona-El Prat Airport",
            originCity: "Porto",
            destinationCity: "Barcelona",
            originIata: "OPO",
            departuretime: 1689611100,
            arrivaltime: 0,
            status: "Scheduled",
            gate: "",
            terminal: "",
            ident_icao: "FR "
          }
        ]
    }
export default flightsData