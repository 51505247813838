<template lang="">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="aqlc39nisa" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 .6C7.046.6.6 7.045.6 15c0 7.953 6.446 14.4 14.4 14.4 7.953 0 14.4-6.447 14.4-14.4C29.4 7.044 22.953.6 15 .6z" fill="#fff"/>
        </mask>
        <g mask="url(#aqlc39nisa)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M.6 15c0 7.953 6.448 14.4 14.4 14.4 7.953 0 14.4-6.447 14.4-14.4C29.4 7.046 22.953.6 15 .6 7.048.6.6 7.046.6 15z" fill="#fff"/>
        </g>
        <path d="m10.2 19.8 9.6-9.6M10.2 10.2l9.6 9.6" stroke="#FF6D6D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped></style>