import Axios from 'axios'
import Cookies from 'js-cookie'
import router from '@/router';

const TOKEN_COOKIE = 'csrftoken';
const CSRF_URL = 'auth/set-csrf/'

const APIRequest = async function(endpoint, action='get', params = {}, headers = null) {
  let csrftoken = Cookies.get(TOKEN_COOKIE)

  if(!csrftoken && (action == 'post'|| action=='put' || action=='patch' || action == 'delete')) {
    await Axios.get(CSRF_URL);
    csrftoken = Cookies.get(TOKEN_COOKIE);
  }

  var config = {
    method: action,
    headers: {},
    // Set the max timeout to 30 seconds
    timeout: 30 * 1000,
    withCredentials: true,
  }

  // if headers is empty
  if(!headers) {
    config.headers['Content-Type'] = 'application/json'

  }else{
    config = {
      ...config,
      ...headers
    }
  }

  if(action == 'post'|| action=='put' || action=='patch'|| action == 'delete') {
    config.headers['X-CSRFToken'] = csrftoken;
  }

  if(action == 'get') {
    config['params'] = params;
  }
  else {
    config['data'] = params;
  }

  return await Axios(endpoint, config).then((response) => {
    return response;
  })
  .catch((error) => {
    if(error.response){
      console.error(error.response);
      if(error.response.status === 403) {
        router.replace({
            name: "logout",
        });
      }
    }
  })
}

export default APIRequest
