import { defineStore, acceptHMRUpdate } from "pinia";
import { useToast } from "vue-toastification";
import dayjs from "dayjs";
import { useAppStore as appStore } from "@/store";
import APIRequest from "@/libs/cloud_auth";

export const useSchedulerStore = defineStore("scheduler", {
  state: () => ({
    isLoading: false,
    calendar: null,
    campaigns: [],
  }),
  getters: {},
  actions: {
    async getCampaigns() {
      try {
        this.isLoading = true;
        const { data } = await APIRequest(`gadgets/campaign/campaign_list/?ncm=${appStore().getCurrentTenant}`);
        this.campaigns = data.campaigns.map(campaign => {
          const color = this.hexToRgb(campaign.color);
          const colorWithOpacity = `rgba(${color.r}, ${color.g}, ${color.b}, 0.25)`;
          let duration = '';

          if(campaign.recurrency_pattern ) {
            const startDateTime = dayjs(campaign.start_date);
            const endDateTime = startDateTime.startOf('day').add( dayjs(campaign.end_date).hour(), 'hour').add( dayjs(campaign.end_date).minute(), 'minute');

            duration = endDateTime.diff(startDateTime);
          }

          return {
            id: campaign.id,
            presentation: campaign.presentation,
            pattern: campaign.recurrency_pattern.replace(/Z/g, ''),
            title: campaign.name,
            contentPlaceholder: campaign.content_placeholder,
            color: colorWithOpacity,
            room: campaign.locations,
            start: campaign.start_date,
            start_date: campaign.start_date,
            end: campaign.end_date,
            end_date: campaign.end_date,
            ...(campaign.recurrency_pattern ? {
              duration,
              rrule: campaign.recurrency_pattern.replace(/Z/g, ''),
            } : {}) //add recurrency if recurrency pattern is not 'once'
          };
        });
        this.isLoading = false;
        this.calendar.addEventSource(this.campaigns);
      } catch(error) {
        console.error(error);
      }
    },
    async addCampaign(form) {
      const start = dayjs(form.date.start).format('YYYY-MM-DD');
      const end = dayjs(form.date.end).format('YYYY-MM-DD');

      const startDateTime = dayjs(`${start} ${form.time.start}`, 'YYYY-MM-DD HH:mm');
      const endDateTime = dayjs(`${end} ${form.time.end}`, 'YYYY-MM-DD HH:mm');


      let config = {
        presentation: form.presentation,
        name: form.name,
        content_placeholder: form.contentPlaceholder.name,
        start_date: dayjs(startDateTime).format('YYYY-MM-DD HH:mm'),
        end_date: dayjs(endDateTime).format('YYYY-MM-DD HH:mm'),
        recurrency_pattern: form.recurrency_pattern,
        color: form.color,
        locations: form.room
      }

      try {
        APIRequest('gadgets/campaign/', 'post', config).then(() => {
          let events = this.calendar.getEventSources()
          for (var i = 0; i < events.length; i++) { 
            events[i].remove(); 
          } 
          this.getCampaigns()
        })
      } catch(error) {
        console.error(error);
      }

      return useToast().success("Campaign added successfully");
    },
    moveCampaign(id, start, end) {
      this.campaigns.forEach((p) => {
        if (p.id == id) {
          p.startDate = dayjs(start).format();
          p.endDate = dayjs(end).format();
        }
      });
    },
    deleteCampaign(id) {
      try {
        APIRequest(`gadgets/campaign/${id}/`,'delete').then(() => {
          let events = this.calendar.getEventSources()
          for (var i = 0; i < events.length; i++) { 
            events[i].remove(); 
          } 
          this.getCampaigns()
        })
      } catch(error) {
        console.error(error);
      }

      return useToast().success("Campaign deleted successfully");
    },
    editCampaign(id, form) {
      const start = dayjs(form.date.start).format('YYYY-MM-DD');
      const end = dayjs(form.date.end).format('YYYY-MM-DD');

      const startDateTime = dayjs(`${start} ${form.time.start}`, 'YYYY-MM-DD HH:mm');
      const endDateTime = dayjs(`${end} ${form.time.end}`, 'YYYY-MM-DD HH:mm');

      let config = {
        presentation: form.presentation,
        name: form.name,
        content_placeholder: form.contentPlaceholder.name,
        start_date: dayjs(startDateTime).format('YYYY-MM-DD HH:mm'),
        end_date: dayjs(endDateTime).format('YYYY-MM-DD HH:mm'),
        recurrency_pattern: form.recurrency_pattern,
        color: form.color,
        locations: form.room
      }

      try {
        APIRequest(`gadgets/campaign/${id}/`, 'put', config).then(() => {
          let events = this.calendar.getEventSources()
          for (var i = 0; i < events.length; i++) { 
            events[i].remove(); 
          } 
          this.getCampaigns()
        })
      } catch(error) {
        console.error(error);
      }
    },
    hexToRgb(hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });
    
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useSchedulerStore, import.meta.hot)
  );
}
