<template>
    <div class="impersonator-modal unselectable">
        <span class="dialog-label">{{ $t('dropdown.impersonation') }}</span>
        <div class="vstack gap-2 mt-4 mb-2">
            <div class="roles-list">
                <div class="hstack gap-2 p-2">
                    <div class="search-input">
                        <input type="text" v-model="search" placeholder="Search" class="search-input">
                    </div>
                    <MSelect width="160px" select-width="186px" :searchable="false" v-model:selected="rolesSelected" :list="roles" :slots="true">
                        <template v-slot:all>
                            {{ $t('dropdown.allRoles') }}
                        </template>
                        <template v-slot:selected>
                            {{ $tc('dropdown.selectedRoles', rolesSelected.length) }}
                        </template>
                        <template v-slot:filter>
                            {{ $t('dropdown.filterRoles') }}
                        </template>
                    </MSelect>
                </div>

                <div class="users-list">
                    <template v-if="!usersList.length && search && !isLoading">
                        <EmptyView class="empty-view">
                            <template v-slot:icon>
                                <Icon name="table_empty" />
                            </template>
                            <template v-slot:title>{{ $t('misc.noResults') }}</template>
                            <template v-slot:text>{{$t('misc.adjustFilter')}}</template>
                        </EmptyView>
                    </template>
                    <template v-else>
                        <div class="user-header">
                            <div></div>
                            <div class="clickable" scope="col">
                                <span class="hstack gap-1" @click="handleSort('full_name')">
                                    {{ $t('table.name') }}
                                    <Icon :class="{ rotated: sort.includes('-') }"
                                        :name="sort.includes('full_name') ? 'table_sort_active' : 'table_sort'" />
                                </span>
                            </div>
                            <div>{{ $t('table.properties') }}</div>
                            <div>{{ $t('table.roles') }}</div>
                        </div>
                        <div class="users-table">
                            <template v-if="!isLoading">
                                <UseVirtualList :list="usersList" :options="{ itemHeight: 40 }" height="360px"
                                    :key="usersList.length">
                                    <template #default="props">
                                        <div class="user-item clickable" @click="selectedUser = props.data.uuid">
                                            <div class="user-row">
                                                <div>
                                                    <Icon :name="selectedUser === props.data.uuid ? 'radio_active' : 'radio'" />
                                                </div>
                                                <div>
                                                    <div class="name-container vstack">
                                                        <span class="font-default">{{ props.data?.full_name  }}</span>
                                                        <span class="font-secondary">{{ props.data?.email }}</span>
                                                    </div>
                                                    
                                                </div>
                                                <div class="font-default">
                                                    {{ props.data.assignedProperties }}
                                                </div>
                                                <div class="d-flex">
                                                    <span class="role-badge"
                                                        :class="role.replace(' ', '-').toLowerCase()"
                                                        @click.stop
                                                        v-for="role in props.data.assignedRoles.slice(0, 2)" :key="role">
                                                        {{ role }}
                                                    </span>
                                                    <VTooltip
                                                        theme="nonius-tooltip"
                                                        v-if="props.data.assignedRoles.length > 2"
                                                    >
                                                        <span class="role-badge more">+{{ props.data.assignedRoles.length - 2 }}</span>
                                                        <template #popper>
                                                            <span :class="role.replace(' ', '-').toLowerCase()" class="role-badge my-2" v-for="role in props.data.assignedRoles.slice(2, props.data.assignedRoles.length)" :key="role">
                                                                {{ role }}
                                                            </span>
                                                        </template>
                                                    </VTooltip>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </UseVirtualList>
                            </template>
                            <template v-else>
                                <div class="spinner-grow m-auto" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="hstack gap-2 mt-4 mb-2">
            <button class="theme-button-sm-icon" @click="this.$emit('close-modal')" type="reset">
                <Icon name="cancel" />
                {{ $t("button.close") }}
            </button>
            <button class="impersonate-btn theme-button-sm-icon btn-bg-conf align-self-auto" @click="seeAsUser()" :class="{disable: !selectedUser}">
                <Icon name="impersonate_user" />
                {{ $t("button.impersonateUser") }}
            </button>
        </div>
    </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { useAppStore } from "@/store";
import { UseVirtualList } from '@vueuse/components';
import EmptyView from "@/components/common/EmptyView.vue";

const roles = ['Admin', 'Nonius Admin', 'Nonius Technical', 'Nonius Engineer', 'Sales Manager', 'Brand Manager', 'Events Manager'];

export default {
    emits: ['close-modal'],
    components: { UseVirtualList, EmptyView },
    setup() {
        const { user } = useAuth0();
        const appStore = useAppStore();
        return { appStore, user };
    },
    data() {
        return {
            search: '',
            sort: 'full_name',
            refresh: false,
            rolesSelected: [],
            roles: roles.map(role => ({ name: role, value: role })),
            users: [],
            selectedUser: null,
            isLoading: true,
        }
    },
    async mounted() {
        this.isLoading = true;
        try {
            this.users = await this.appStore.getCloudUsers();
            // Created a new field called full_name that concats first_name with last_name
            this.users['result'].forEach((user) => {
                user.full_name = `${user.first_name} ${user.last_name}`;
            }) 

            // Removed user from auth0
            this.users['result'] = this.users['result'].filter(user => user.email !== this.user.email);

        } catch (error) {
            console.log(error);
        }
        this.isLoading = false;
    },
    methods: {
        handleSort(value) {
            this.sort = this.sort === value ? `-${value}` :
                this.sort === `-${value}` ? '' : value;
        },
        getNestedValue(object, path) {
            return path.split('.').reduce((obj, key) => (obj && obj[key] !== undefined) ? obj[key] : '', object).toLowerCase();
        },
        async seeAsUser() {
            const payload = this.users['result'].find(user => user.uuid === this.selectedUser);            
            if(await this.appStore.impersonateLogin(payload.uuid, payload.email, payload.full_name)){
                window.location.reload();
            }
        }
    },
    watch: {
        search() {
            this.refresh = !this.refresh;
        },
    },
    computed: {
        usersList() {
            if (!this.users['result']) return [];
            let filteredItems = Object.create(this.users['result']);

            if (this.search) {
                const search = this.search.toLowerCase();
                filteredItems = filteredItems.filter((user) =>
                    user.full_name.toLowerCase().includes(search.toLowerCase())
                    || user.email.toLowerCase().includes(search.toLowerCase())
                );
            }

            if(this.rolesSelected.length > 0) {
                filteredItems = filteredItems.filter((user) => {
                    return this.rolesSelected.some(role => user.assignedRoles.includes(role));
                });
            }

            const sortField = this.sort.replace('-', ''); // Get the field name, e.g. "name"
            const isDescending = this.sort.startsWith('-'); // Check if it's descending

            if (sortField)
                filteredItems.sort((a, b) => {
                    const aValue = this.getNestedValue(a, sortField).normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    const bValue = this.getNestedValue(b, sortField).normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                    if (aValue < bValue) return isDescending ? 1 : -1;
                    if (aValue > bValue) return isDescending ? -1 : 1;
                    return 0; // They are equal
                });

            return filteredItems
        },
    }
}
</script>

<style lang="scss" scoped>
.impersonator-modal {
    display: flex;
    flex-direction: column;

    .roles-list {
        background: #fff;
        width: 880px;
        height: 463px;
    }

    .users-list {
        height: 100%;
        padding: 10px 5px 10px 10px;

        .empty-view{
            padding-bottom: 90px;
        }

        .user-header {
            padding-right: 20px;
            font-size: 14px;
            color: #8D8D8D;
            letter-spacing: 0.233px;
            font-weight: 600;
            display: flex;
        }

        .users-table {
            min-height: 360px;
            max-height: 360px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        // only div child
        .users-table > div {
            scrollbar-gutter: stable;
            padding-right: 5px;
        }
        

        .user-item {
            display: flex;
            flex-direction: column;
            height: 40px;
            min-height: 40px;
            margin-bottom: 5px;

            .user-row {
                display: flex;
                align-items: center;
                height: 100%;
                background: #F4F4F4;
                border-radius: 5px;

                .font-default {
                    font-size: 16px;
                    color: #5F5F5F;
                }

                .font-secondary {
                    font-size: 12px;
                    color: #8D8D8D;
                }

                .name-container {
                    display: flex;
                    flex-direction: column;
                    letter-spacing: 0.5px;
                    font-weight: 400;
                    line-height: 1.1;
                }
            }
        }
    }
}

.user-header{
    & > div:nth-child(1) {
        width: 40px;
    }

    & > div:nth-child(2) {
        width: 35%;
    }

    & > div:nth-child(3) {
        width: 20%;
    }

    & > div:nth-child(4) {
        width: 45%;
    }
} 

.user-row {
    & > div:nth-child(1) {
        min-width: 40px;
        height: 30px;
        text-align: center;
    }

    & > div:nth-child(2) {
        width: 35%;
    }

    & > div:nth-child(3) {
        width: 20%;
    }

    & > div:nth-child(4) {
        width: 45%;
    }
}

.impersonate-btn.disable{
    opacity: 0.4;
}

.rotated {
  transform: rotate(180deg);
}
</style>