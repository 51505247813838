// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-view[data-v-48d9a75e] {
  place-items: center;
  margin: auto;
  display: flex;
  gap: 4px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 100%;
}
.empty-view .text-1[data-v-48d9a75e] {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  color: #8d8d8d;
}
.empty-view .text-2[data-v-48d9a75e] {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.5px;
  color: #8d8d8d;
  white-space: pre-line;
}
.empty-view .icon[data-v-48d9a75e] {
  background: #ededed;
  border-radius: 5px;
  width: 57px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
