// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tv-wrapper[data-v-51995880] {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tv-widget[data-v-51995880] {
  display: flex;
}
[data-v-51995880] .tv-live .icon {
  background: unset;
  width: 104px;
  height: 104px;
  margin-bottom: 15px;
}
[data-v-51995880] .tv-live .text-1 {
  font-size: 24px;
}
[data-v-51995880] .tv-live .text-2 {
  font-size: 20px;
}
.tv-screen[data-v-51995880] {
  background: black;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.tv-rect[data-v-51995880] {
  position: absolute;
  top: 25%;
  right: 0;
  width: 311px;
  height: auto;
  background-color: rgba(115, 115, 115, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 15px 0 0 15px;
  box-shadow: 0 4.3px 5.7px 0 rgba(0, 0, 0, 0.18);
  z-index: 10000;
}
.tv-rect .tv-navigation[data-v-51995880] {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  color: #fff;
}
.tv-rect .tv-navigation .channel-info[data-v-51995880] {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 54px;
  min-height: 54px;
  gap: 15px;
  padding-left: 15px;
}
.tv-rect .tv-navigation .channel-info.active[data-v-51995880] {
  background-color: rgba(0, 0, 0, 0.4);
}
.tv-rect .tv-navigation .channel-info span[data-v-51995880] {
  width: 50px;
  text-align: left;
  display: flex;
  justify-content: center;
}
.tv-rect .tv-navigation .channel-info span.channel-nr[data-v-51995880] {
  font-size: 42.7px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
}
.tv-rect .tv-navigation .channel-info div[data-v-51995880] {
  font-size: 24.2px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  color: #fff;
}
.v-enter-active[data-v-51995880],
.v-leave-active[data-v-51995880] {
  transition: opacity 0.2s ease-in-out;
}
.v-enter-from[data-v-51995880],
.v-leave-to[data-v-51995880] {
  opacity: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
