import {createRouter, createWebHistory} from 'vue-router'
import LogoutView from '@/views/LogoutView.vue'
import {authGuard} from "@auth0/auth0-vue";

import LoginView from '@/views/LoginView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import BuildPage from "@/components/composer/builder/buildView.vue"
import {useAppStore} from '@/store';
import RedirectView from "@/views/RedirectView.vue";
import NoPermissionView from "@/views/NoPermissionView.vue";
import displayView from '@/components/display/DisplayView.vue'

function applyAuthGuard(routes, guard) {
    return routes.map(route => ({...route, beforeEnter: guard}));
}

const publicRoutes = [
    { path: '/', component: RedirectView },
    { path: '/403', name: '403', component: NoPermissionView },
    { path: '/login', name: 'login', component: LoginView },
    { path: '/logout', name: 'logout', component: LogoutView },
    { path: '/preview', name: 'preview', component: BuildPage },
    { path: '/display/:mac', name: 'displayView', component: displayView },
    { path: '/:pathMatch(.*)*', component: NotFoundView }
];

// Define protected routes with meta roles
const protectedRoutes = [
    {
        path: '/conferences',
        name: 'conferences',
        component: () => import('@/views/ConferencesView.vue'),
        meta: { roles: ['client-admin', 'nonius-admin', 'client-events-manager', 'client-sales-manager'] }
    },
    {
        path: '/scheduler',
        name: 'scheduler',
        component: () => import('@/views/SchedulerView.vue'),
        meta: { roles: ['client-admin', 'nonius-admin', 'client-events-manager', 'client-sales-manager'] }
    },
    {
        path: '/presentations',
        name: 'presentations',
        component: () => import('@/views/PresentationsView.vue'),
        meta: { roles: ['client-admin', 'nonius-admin', 'client-events-manager', 'client-brand-manager', 'client-sales-manager'] }
},
    {
        path: '/templates',
        name: 'templates',
        component: () => import('@/views/TemplatesView.vue'),
        meta: { roles: ['client-admin', 'nonius-admin', 'client-brand-manager'] }
    },
    {
        path: '/players',
        name: 'players',
        component: () => import('@/views/PlayersView.vue'),
        meta: { roles: ['client-admin', 'nonius-admin', 'client-sales-manager', 'nonius-technical', 'nonius-engineer'] }
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('@/views/MediaView.vue'),
        meta: { roles: ['client-admin', 'nonius-admin', 'client-events-manager', 'client-brand-manager', 'client-sales-manager'] }
    },
    {
        path: '/diagnostics',
        name: 'diagnostics',
        children: [
            {
                path: 'troubleshooting',
                name: 'troubleshooting',
                component: () => import('@/views/TroubleshootingView.vue'),
                meta: { roles: ['client-admin', 'nonius-admin', 'nonius-engineer'] }
            }
        ]
    },
    {
        path: '/software',
        name: 'software',
        children: [
            {
                path: 'management',
                name: 'management',
                component: () => import('@/views/ManagementView.vue'),
                meta: { roles: ['nonius-admin', 'nonius-technical', 'nonius-engineer'] }
            },
            {
                path: 'devices',
                name: 'devices',
                component: () => import('@/views/DevicesView.vue'),
                meta: { roles: ['nonius-admin', 'nonius-technical', 'nonius-engineer'] }
            }
        ]
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/SettingsView.vue'),
        meta: { roles: ['client-admin', 'nonius-admin', 'client-brand-manager', 'client-sales-manager', 'nonius-technical', 'nonius-engineer'] }
    },
    {
        path: '/composer/:id',
        name: 'composer',
        component: () => import('@/components/composer/views/ComposerPage.vue'),
        props: true
    },
];

const guardedRoutes = applyAuthGuard(protectedRoutes, authGuard);
const routes = [...publicRoutes, ...guardedRoutes];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // Check page if is protected by roles
    if (!to.meta.roles || !useAppStore().tenant) return next()
    const roles = useAppStore().tenant.role;

    if (!to.meta.roles.includes(roles)) {
        // The user will get redirected to the first route he has access
        return next('/')
    }

    return next()
});

export default router
