<template>
  <div></div>
</template>
  
<script setup>
import { onMounted } from 'vue';
import { useAuth0 } from "@auth0/auth0-vue";

const { logout } = useAuth0();
import APIRequest from '@/libs/cloud_auth';

const handleLogout = () =>
  logout({
    logoutParams: {
      returnTo: window.location.origin,
    }
  });

onMounted( async () => {
  // Call handleLogout when the component is mounted
  await APIRequest('auth/logout/', 'post').then(() => {
    localStorage.clear()
    handleLogout();
  }).catch((error) => {
    console.error('Failed to Login: ' + error)
  })

});
</script>
