<template>
  <div style="display: contents;">
  <div
    v-if="element.type != 'star' && element.type != 'hexagon' && element.type != 'pentagon' && element.type != 'los' && element.type != 'triangle' && element.type != 'line'"
    :class="element.type + ' back'"
    :style="[{ 'background-color': element.styles.background, 
      'border-width': element.styles.borderWidth, 
      'border-color': element.styles.borderColor, 
      'border-style': element.styles.stroke === 0 ? 'solid' : element.styles.stroke === 1 ? 'dotted' : 'dashed',
    }]"
    id="back"></div>

  <div v-if="element.type == 'star'">
    <svg preserveAspectRatio="xMidYMid slice" :width="element.width" :height="element.height" viewBox="0 0 35 36" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m17.5 9.618 1.994 6.137.112.346h6.817l-5.22 3.793-.295.213.112.346 1.995 6.137-5.221-3.793-.294-.213-.294.213-5.22 3.793 1.993-6.137.113-.346-.294-.213-5.22-3.793h6.816l.112-.346L17.5 9.618z"
        :fill="element.styles['background']" :stroke="element.styles.borderColor"
        :stroke-width="element.styles.borderWidth"
        :stroke-dasharray="element.styles.stroke"
        />
    </svg>
  </div>

  <div v-if="element.type == 'hexagon'">
    <svg preserveAspectRatio="xMidYMid slice" :width="element.width" :height="element.height" viewBox="0 0 35 36" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.474 12.789 17.5 7.577l9.026 5.212V23.21L17.5 28.423 8.474 23.21V12.79z"
        :fill="element.styles['background']" :stroke="element.styles.borderColor"
        :stroke-width="element.styles.borderWidth" 
        :stroke-dasharray="element.styles.stroke"
        />
    </svg>
  </div>

  <div v-if="element.type == 'pentagon'">
    <svg preserveAspectRatio="xMidYMid slice" :width="element.width" :height="element.height" viewBox="0 0 35 36" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M7.626 15.792 17.5 8.618l9.874 7.174-3.772 11.607H11.398L7.626 15.792z"
        :fill="element.styles['background']" :stroke="element.styles.borderColor"
        :stroke-width="element.styles.borderWidth"
        :stroke-dasharray="element.styles.stroke"
        />
    </svg>
  </div>

  <div v-if="element.type == 'los'">
    <svg preserveAspectRatio="xMidYMid slice" :width="element.width" :height="element.height" viewBox="0 0 35 36" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.207 18 17.5 8.707 26.793 18 17.5 27.293 8.207 18z" :fill="element.styles['background']"
        :stroke="element.styles.borderColor" :stroke-width="element.styles.borderWidth"  :stroke-dasharray="element.styles.stroke" />
    </svg>
  </div>

  <div v-if="element.type == 'triangle'">
    <svg 
    preserveAspectRatio="xMidYMid slice"
    :width="element.width"
    :height="element.height" 
    viewBox="0 0 35 36" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M7.122 26.21 17.5 8.815 27.878 26.21H7.122z" 
      :fill="element.styles.background"
      :stroke="element.styles.borderColor" 
      :stroke-width="element.styles.borderWidth"
      :stroke-dasharray="element.styles.stroke"
    />
  </svg>
  </div>

  <div v-if="element.type == 'line'">
    <div class="line-container">
      <div :style="{
        'margin-top': (element.height / 2) - (parseInt(element.styles.borderWidth)/1.5)  +'px',
        'border-bottom': 
          `${element.styles.borderWidth} 
          ${element.styles.stroke === 0 ? 'solid' : element.styles.stroke === 1 ? 'dotted' : 'dashed'} 
          ${element.styles.borderColor}`,
      }" class="inner"></div>
    </div>

  </div> 
  </div>
</template>
<script>

export default {
  props: ["element", "styles"],
};

</script>
<style scoped>
.square {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  border: 6pt solid;
  border-color: #000;
}

.squareRadius {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  border: 6pt solid #000;
  border-color: #000;
  border-radius: 30px;
}
.back {
  width: 100%;
  height: 100%;
}
.circle {
  border-radius: 50%;
  border: 6pt solid;
  border-color: #000;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
</style>
