// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button[data-v-56606f63] {
  background-color: #484848;
  width: 44px;
  height: 40px;
  display: flex;
  justify-content: center;
  place-items: center;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
  overflow: hidden;
}
.icon-button[data-v-56606f63] {
  height: 38px;
  stroke: #b3b3b3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-button-with-submenu[data-v-56606f63] {
  height: 38px;
  stroke: #b3b3b3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button.expanded[data-v-56606f63] {
  width: 183px;
  justify-content: flex-start;
  padding: 0 12px;
}
.button.expanded .icon-button[data-v-56606f63] {
  width: auto;
  text-transform: capitalize;
}
.button.expanded .icon-button-with-submenu[data-v-56606f63] {
  width: 100%;
  text-transform: capitalize;
}
.menu-arrow[data-v-56606f63] {
  transform: rotateX(180deg);
  filter: invert(79%) sepia(9%) saturate(11%) hue-rotate(314deg) brightness(91%) contrast(92%);
}
.menu-text[data-v-56606f63] {
  text-wrap: wrap;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.5px;
  color: #b5b5b5;
  padding-left: 8px;
}
.menu-text.active[data-v-56606f63] {
  color: #484848;
}
a[data-v-56606f63],
a[data-v-56606f63]:hover,
a[data-v-56606f63]:visited,
a[data-v-56606f63]:active {
  color: inherit;
  text-decoration: none;
}
a:hover .button[data-v-56606f63] {
  background-color: #595959;
}
a .button.active[data-v-56606f63] {
  background-color: #f5f5f5;
  color: black;
}
.button.active img[data-v-56606f63] {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}
.circle-container[data-v-56606f63] {
  width: 20px;
  display: flex;
  align-items: center;
  justify-items: center;
}
.circle-container .circle[data-v-56606f63] {
  margin: auto;
  border-radius: 50%;
  background-color: #4195ff;
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-container .circle .inner-circle[data-v-56606f63] {
  border-radius: 50%;
  background-color: #fff;
  height: 6px;
  width: 6px;
}
.slide-up-enter-active[data-v-56606f63] {
  transition: all 0.1s ease-out;
}
.slide-up-leave-active[data-v-56606f63] {
  transition: all 0.1s ease-in;
}
.slide-up-enter-to[data-v-56606f63],
.slide-up-leave-from[data-v-56606f63] {
  overflow: hidden;
  max-height: 40px;
}
.slide-up-enter-from[data-v-56606f63],
.slide-up-leave-to[data-v-56606f63] {
  overflow: hidden;
  max-height: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
