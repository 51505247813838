// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProseMirror {
  height: 100% !important;
  overflow: hidden;
  outline: none !important;
  font-size: 32px;
}
.ProseMirror * {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.ProseMirror p {
  margin: 0;
}
.text-editor {
  height: 100% !important;
  padding-inline: 10px;
  cursor: text;
}
:deep(.text-editor u) {
  text-decoration: none !important;
}
:deep(.text-editor u *) {
  text-decoration: underline;
}
::selection {
  background: rgba(133, 133, 133, 0.315) !important;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
