// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.impersonator-modal[data-v-092dd183] {
  display: flex;
  flex-direction: column;
}
.impersonator-modal .roles-list[data-v-092dd183] {
  background: #fff;
  width: 880px;
  height: 463px;
}
.impersonator-modal .users-list[data-v-092dd183] {
  height: 100%;
  padding: 10px 5px 10px 10px;
}
.impersonator-modal .users-list .empty-view[data-v-092dd183] {
  padding-bottom: 90px;
}
.impersonator-modal .users-list .user-header[data-v-092dd183] {
  padding-right: 20px;
  font-size: 14px;
  color: #8D8D8D;
  letter-spacing: 0.233px;
  font-weight: 600;
  display: flex;
}
.impersonator-modal .users-list .users-table[data-v-092dd183] {
  min-height: 360px;
  max-height: 360px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.impersonator-modal .users-list .users-table > div[data-v-092dd183] {
  scrollbar-gutter: stable;
  padding-right: 5px;
}
.impersonator-modal .users-list .user-item[data-v-092dd183] {
  display: flex;
  flex-direction: column;
  height: 40px;
  min-height: 40px;
  margin-bottom: 5px;
}
.impersonator-modal .users-list .user-item .user-row[data-v-092dd183] {
  display: flex;
  align-items: center;
  height: 100%;
  background: #F4F4F4;
  border-radius: 5px;
}
.impersonator-modal .users-list .user-item .user-row .font-default[data-v-092dd183] {
  font-size: 16px;
  color: #5F5F5F;
}
.impersonator-modal .users-list .user-item .user-row .font-secondary[data-v-092dd183] {
  font-size: 12px;
  color: #8D8D8D;
}
.impersonator-modal .users-list .user-item .user-row .name-container[data-v-092dd183] {
  display: flex;
  flex-direction: column;
  letter-spacing: 0.5px;
  font-weight: 400;
  line-height: 1.1;
}
.user-header > div[data-v-092dd183]:nth-child(1) {
  width: 40px;
}
.user-header > div[data-v-092dd183]:nth-child(2) {
  width: 35%;
}
.user-header > div[data-v-092dd183]:nth-child(3) {
  width: 20%;
}
.user-header > div[data-v-092dd183]:nth-child(4) {
  width: 45%;
}
.user-row > div[data-v-092dd183]:nth-child(1) {
  min-width: 40px;
  height: 30px;
  text-align: center;
}
.user-row > div[data-v-092dd183]:nth-child(2) {
  width: 35%;
}
.user-row > div[data-v-092dd183]:nth-child(3) {
  width: 20%;
}
.user-row > div[data-v-092dd183]:nth-child(4) {
  width: 45%;
}
.impersonate-btn.disable[data-v-092dd183] {
  opacity: 0.4;
}
.rotated[data-v-092dd183] {
  transform: rotate(180deg);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
