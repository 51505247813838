<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="error-template">
          <h2>{{ $t("error.notFound") }}</h2>
          <div class="error-details">
            {{ $t("error.message") }}
          </div>
          <div class="error-actions">
            <a href="/" class="btn btn-primary btn-lg"
              ><span class="glyphicon glyphicon-home"></span>
              {{ $t("error.goHome") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
