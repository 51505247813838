<template>
  <div class="hstack gap-2 ms-auto">
    <ModalView v-if="listModal" @close="listModal = false">
      <div class="dialog-label">{{ $t('menu.language') }}</div>

      <div class="tenant-frame my-2">
        <div class="tenant-list">
          <div v-for="item in computedLanguages" :key="item.code" class="tenant-item clickable"
               @click="lang = item.code"
               :class="{ active: lang === item.code }">
            {{ item.name }}&nbsp;({{ item.code }})
          </div>
          <div class="ms-1" v-if="!computedLanguages.length">{{ $t('misc.noResults') }}</div>
        </div>
      </div>

      <div class="hstack gap-2 mt-4 mb-2">
        <button class="theme-button-sm-icon" @click="listModal = false" type="reset">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="vsddiqzmma" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="1" y="2" width="18" height="17">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M10 2.318A8.18 8.18 0 0 0 1.818 10.5a8.182 8.182 0 1 0 16.364 0A8.181 8.181 0 0 0 10 2.32z"
                    fill="#fff"/>
            </mask>
            <g mask="url(#vsddiqzmma)">
              <path clip-rule="evenodd" d="M1.818 10.5a8.182 8.182 0 1 0 16.364-.002 8.182 8.182 0 0 0-16.364.002z"
                    stroke="#484848" stroke-width="3"/>
            </g>
            <path d="M12.226 12.726 7.773 8.273M12.226 8.273l-4.453 4.453" stroke="#484848" stroke-width="1.5"
                  stroke-linecap="round"/>
          </svg>
          {{ $t("button.cancel") }}
        </button>
        <button class="theme-button-sm-icon btn-bg-conf align-self-auto" @click="changeLang(lang)">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.66 3.682a.212.212 0 0 0-.212.212v1.725a1.818 1.818 0 0 1-1.818 1.818H6.967A1.818 1.818 0 0 1 5.149 5.62V4.252a.57.57 0 0 0-.57-.57c-.772 0-1.397.625-1.397 1.396v10.844c0 .771.625 1.396 1.396 1.396h10.844c.771 0 1.396-.625 1.396-1.396V5.84L14.66 3.682zm-1.576-.614a.75.75 0 0 1 .75-.75h.85c.346 0 .678.138.923.383L17.8 4.893c.245.245.383.577.383.923v10.106a2.76 2.76 0 0 1-2.76 2.76H4.578a2.76 2.76 0 0 1-2.76-2.76V5.078a2.76 2.76 0 0 1 2.76-2.76h1.184a.75.75 0 0 1 .75.75V5.62c0 .251.204.455.455.455h5.663a.455.455 0 0 0 .454-.455v-2.55z"
                  fill="#fff"/>
            <rect width="10" height="1.5" rx=".75" transform="matrix(1 0 0 -1 5 11)" fill="#fff"/>
            <rect width="10" height="1.5" rx=".75" transform="matrix(1 0 0 -1 5 15)" fill="#fff"/>
          </svg>

          {{ $t("button.save") }}
        </button>
      </div>
    </ModalView>

    <div class="user-dropdown" v-if="tenants">
      <div class="user-select">
        <HotelList :readonly="this.$route.name === 'templates'"
                   :disabled="disabled"
                   v-model="selected"
                   :options='getRoleList()'/>
        <template>
          <svg class="ms-2" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6 15.63H2.4c-.247 0-.45-.245-.45-.54 0-.296.203-.54.45-.54h13.2c.245 0 .45.244.45.54 0 .295-.205.54-.45.54z"
                fill="#4195FF"/>
            <path
                d="M13.68 15.455c-.295 0-.54-.204-.54-.45V12.33c0-.246.245-.45.54-.45.295 0 .54.204.54.45v2.676c0 .246-.238.45-.54.45z"
                fill="#4195FF"/>
            <path
                d="M13.678 12.78a1.77 1.77 0 0 1-1.77-1.77V9.648a1.77 1.77 0 1 1 3.54 0v1.362a1.77 1.77 0 0 1-1.77 1.77zm0-3.996a.87.87 0 0 0-.87.87v1.362a.87.87 0 0 0 1.74 0V9.654a.87.87 0 0 0-.87-.87zM10.38 15.45a.453.453 0 0 1-.45-.45V5.418c0-.954-.402-1.362-1.338-1.362H4.854c-.942 0-1.35.408-1.35 1.362V15c0 .246-.204.45-.45.45a.453.453 0 0 1-.45-.45V5.418c0-1.458.798-2.262 2.25-2.262h3.738c1.446 0 2.238.804 2.238 2.262V15c0 .246-.204.45-.45.45z"
                fill="#4195FF"/>
            <path
                d="M8.249 7.38h-2.97c-.246 0-.45-.245-.45-.54 0-.295.204-.54.45-.54h2.97c.246 0 .45.245.45.54 0 .295-.204.54-.45.54zM8.249 9.63h-2.97c-.246 0-.45-.245-.45-.54 0-.295.204-.54.45-.54h2.97c.246 0 .45.245.45.54 0 .295-.204.54-.45.54zM6.84 15.45c-.295 0-.54-.205-.54-.45v-2.25c0-.247.245-.45.54-.45.295 0 .54.203.54.45V15c0 .245-.245.45-.54.45z"
                fill="#4195FF"/>
          </svg>

          <div class="user-name-container">
            <span class="user-name">{{ appStore.getHotelName(selected) }}</span>
          </div>
        </template>
      </div>


    </div>
    <OnClickOutside @trigger="this.openDrop = false">
      <div class="dropdown drop-circle">
        <button type="button" class="p-0 drop-button avatar-button" :class="{ active: openDrop }"
                @click.prevent="openDrop = !openDrop">
          <img :alt="user.name[0].toUpperCase()" :src="user.picture" referrerpolicy="no-referrer"
               :style="{width: '100%', height: '100%', borderRadius: '50%'}"/>
        </button>

        <div class="dropdown-container" ref="dropdownContainer" v-if="openDrop">
          <div class="arrow-up"></div>
          <div class="user-role">
            <span>{{ user.name }}</span>
            <span>{{ user.email }}</span>
            <span>{{ $t(`roles.${appStore.getCurrentRole}`) }}</span>
          </div>
          <div class="divider-horizontal"/>
          <div class="dropdown-list" ref="dropdownList" >
            <!-- <div class="drop-item">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="5.909" r="3.341" stroke="#8D8D8D" stroke-width="1.5"/>
                <path
                    d="M16.977 15.455c0 .218-.095.443-.4.691-.322.261-.829.504-1.513.706-1.363.402-3.214.58-5.064.58s-3.7-.178-5.064-.58c-.684-.202-1.19-.445-1.512-.706-.306-.248-.401-.473-.401-.691 0-.26.134-.63.506-1.08.363-.44.906-.894 1.584-1.305 1.36-.824 3.16-1.41 4.887-1.41 1.728 0 3.527.586 4.887 1.41.678.411 1.22.865 1.584 1.305.372.45.506.82.506 1.08z"
                    stroke="#8D8D8D" stroke-width="1.5"/>
              </svg>
              {{ $t('menu.profile') }}
            </div> -->
            <div class="drop-item" @click="redirectUser()" v-if="appStore.getCurrentRole === 'nonius-admin' || appStore.getCurrentRole === 'client-admin' ">
              <Icon name="user_management"/>
              User Management
            </div>
            <div class="drop-item" @click="this.$router.replace({name: 'settings'})">
              <Icon name="settings"/>
              {{ $t('menu.settings') }}
            </div>
            <div class="drop-item" @click="listModal = true, this.openDrop = false">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 18.063c-4.447 0-8.063-3.615-8.063-8.063 0-4.447 3.615-8.062 8.063-8.062 4.447 0 8.063 3.615 8.063 8.062 0 4.448-3.615 8.063-8.063 8.063zm0-15A6.946 6.946 0 0 0 3.062 10 6.946 6.946 0 0 0 10 16.938 6.946 6.946 0 0 0 16.938 10 6.946 6.946 0 0 0 10 3.063z"
                    fill="#8D8D8D"/>
                <path
                    d="M7.75 17.313H7a.567.567 0 0 1-.563-.563c0-.308.24-.555.548-.563a22.118 22.118 0 0 1 0-12.375.559.559 0 0 1-.548-.562c0-.308.255-.563.563-.563h.75c.18 0 .352.09.457.233.105.15.135.337.075.51a20.961 20.961 0 0 0 0 13.148c.06.172.03.36-.075.51a.59.59 0 0 1-.457.224zM12.25 17.313a.559.559 0 0 1-.533-.742 20.961 20.961 0 0 0 0-13.148.562.562 0 1 1 1.065-.36 21.988 21.988 0 0 1 0 13.852.558.558 0 0 1-.532.398z"
                    fill="#8D8D8D"/>
                <path
                    d="M10 13.9c-2.093 0-4.178-.293-6.188-.885a.564.564 0 0 1-.562.547.567.567 0 0 1-.563-.562v-.75c0-.18.09-.352.233-.457a.563.563 0 0 1 .51-.075 20.961 20.961 0 0 0 13.148 0c.172-.06.36-.03.51.075.15.105.232.277.232.457V13a.567.567 0 0 1-.563.563.559.559 0 0 1-.562-.548A21.972 21.972 0 0 1 10 13.9zM16.75 8.313a.504.504 0 0 1-.18-.03 20.961 20.961 0 0 0-13.147 0 .562.562 0 0 1-.713-.353.572.572 0 0 1 .36-.712 21.988 21.988 0 0 1 13.853 0c.292.097.457.42.352.712a.547.547 0 0 1-.525.383z"
                    fill="#8D8D8D"/>
                <circle cx="10" cy="10" r="7.25" stroke="#8D8D8D" stroke-width="1.5"/>
                <path d="M3 8c2.333-1 8.4-2.4 14 0M3 12c2.333 1 8.4 2.4 14 0" stroke="#8D8D8D" stroke-width="1.5"/>
                <path d="M12 17c1-2.333 2.4-8.4 0-14M7.925 17c-1-2.333-2.4-8.4 0-14" stroke="#8D8D8D"
                      stroke-width="1.5"/>
              </svg>
              {{ $t('menu.language') }}
              <span class="ms-auto">{{ lang }}</span>
            </div>
            <div class="divider-horizontal"/>
            <div class="drop-item" @click="signOut">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.727 10h9.546m0 0L15 12.273M17.273 10 15 7.727M11.818 13.232v.04a4 4 0 0 1-4 4h-1.09a4 4 0 0 1-4-4V6.728a4 4 0 0 1 4-4h1.09a4 4 0 0 1 4 4v.04"
                    stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ $t("menu.logout") }}
            </div>
          </div>
        </div>
      </div>
    </OnClickOutside>
  </div>
</template>
<script>
import {useAuth0} from '@auth0/auth0-vue';
import {OnClickOutside} from "@vueuse/components";
import HotelList from "@/components/HotelList";
import {useAppStore} from "@/store";
import ModalView from "@/components/helpers/ModalView.vue";

export default {
  props: {
    tenants: {
      required: false,
      default: true,
    },
    disabled: [Boolean]
  },
  setup() {
    const appStore = useAppStore();
    const {user} = useAuth0();

    return {appStore, user};
  },
  components: {OnClickOutside, HotelList, ModalView},
  data() {
    return {
      numRows: 4,
      openDrop: false,
      listModal: false,
      lang: localStorage.getItem("lang"),
      selected: this.appStore.getCurrentTenant,
    };
  },
  mounted(){
    if (this.appStore.getCurrentRole === 'nonius-admin' || this.appStore.getCurrentRole === 'client-admin') {
      this.numRows = 5;
    }
  },
  methods: {
    redirectUser(){
      window.location.href = process.env.VUE_APP_USER_MANAGEMENT_URL +
          '?action=management' +
          '&client_id=' +
          process.env.VUE_APP_USER_MANAGEMENT_CLIENT_ID; // Redirect to external site
    },
    getRoleList() {
      return this.appStore.hotels
    },
    signOut() {
      this.$router.replace({
        name: "logout",
      });
    },
    changeLang(value) {
      localStorage.setItem("lang", value);
      this.$dayjs.locale(value)
      window.location.reload();
    },
  },
  watch: {
    async selected(val) {
      this.appStore.appLoading = true;
      await this.appStore.setTenantSelect(val);
      window.location.reload()
    },
    listModal(val) {
      if (!val) {
        this.lang = localStorage.getItem("lang");
      }
    }
  },
  computed: {
    getHeight(){
      return (35 * this.numRows) + "px";
    },
    computedLanguages() {
      return this.appStore.appLanguages;
    }
  }
};
</script>
<style lang="scss" scoped>
.tenant-list {
  display: flex;
  gap: 5px;
  flex-direction: column;

  & .tenant-item {
    background-color: #f4f4f4;
    padding: 7px 8px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #3c3c3c;

    &.active {
      background-color: #3c3c3c;
      color: #fff;
    }
  }
}

.tenant-frame {
  background: #ffffff;
  width: 390px;
  height: 408px;
  padding: 12px 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.drop-button.avatar-button {
  transition: 0.2s all ease-in-out;
  border-radius: 100%;
  font-size: 22px;
  font-weight: 600;
  color: white;
  outline: 2px solid white;
  background-color: #484848;
  width: 30px;
  height: 30px;
  display: flex;
  place-content: center;
  align-items: center;

  &:hover,
  &.active {
    outline-color: #4195ff;
  }
}

.drop-circle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #dbdbdb;
  //margin-right: 0.35rem;
}

.user-dropdown {
  width: 223px;
  height: 40px;
  background-color: #ffffff;
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
}

.user-select {
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-name-container {
  height: 30px;
  overflow: hidden;
  width: 150px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484848;

  &::after {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  &.tenant-list {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.user-name {
  display: inline-block;
  display: -webkit-inline-box;
  max-height: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.dropdown-container {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 10000;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 5px;
  width: 202px;
  height: auto;

  & .arrow-up {
    filter: drop-shadow(1px -5px 2px rgb(0 0 0 / 0.1));
  }
}

.user-role{
  padding: 15px 15px 10px;
  display: flex;
  flex-direction: column;

  span:nth-child(1){
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: #484848;
  }

  span:nth-child(2){
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 5px;
    color: #8d8d8d;
  }

  span:nth-child(3){
    padding: 0 8px;
    border-radius: 5px;
    background-color: #5f5f5f;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    text-align: left;
    max-width: max-content;
    color: #fff;
  }
}

.dropdown-list {
  width: 202px;
  text-align: left;
  height: auto;
  margin-bottom: 5px;

  & .drop-item {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 5px;
    padding-inline: 15px;
    height: 35px;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.5px;
    text-align: left;
    color: #5f5f5f;

    &:hover {
      background: #f4f4f4;
    }
  }
}

.arrow-up {
  width: 0;
  height: 0;
  position: absolute;
  right: 12px;
  top: -10px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid white;
}
</style>
