<template>
  <div class="table-editor" ref="el" v-if="editor" @click="selectEditor">
    <editor-content class="editor" :editor="editor" />
  </div>
</template>

<script>
import { composerStore } from "@/store";
import { useElementSize } from '@vueuse/core'
import { ref } from 'vue'

import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import StarterKit from '@tiptap/starter-kit'
import { Color } from "@tiptap/extension-color";
import { Editor, EditorContent } from '@tiptap/vue-3'
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from '@tiptap/extension-font-family'
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import FontSize from "tiptap-extension-font-size";

import { Extension } from "@tiptap/core";

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-background-color'),
        renderHTML: attributes => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
      },

      borderColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-border-color'),
        renderHTML: attributes => {
          return {
            'data-border-color': attributes.borderColor,
            style: `border-color: ${attributes.borderColor}`,
          }
        },
      },

      borderWidth: {
        default: null,
        parseHTML: element => element.getAttribute('data-border-width'),
        renderHTML: attributes => {
          return {
            'data-border-width': attributes.borderWidth,
            style: `border-width: ${attributes.borderWidth}`,
          }
        },
      },

      borderStyle: {
        default: null,
        parseHTML: element => element.getAttribute('data-border-style'),
        renderHTML: attributes => {
          return {
            'data-border-style': attributes.borderStyle,
            style: `border-style: ${attributes.borderStyle}`,
          }
        },
      },
    }
  },
})

const DisableKeys = Extension.create({
  addKeyboardShortcuts() {
    return {
      'Mod-Alt-1': () => true,
      'Mod-Alt-2': () => true,
      'Mod-Alt-3': () => true,
      'Mod-Alt-4': () => true,
      'Mod-Alt-5': () => true,
      'Mod-Alt-6': () => true,
    };
  },
});

export default {
  props: ["element", "index"],

  components: {
    EditorContent,
  },

  setup() {
    const elementsStore = composerStore();
    const el = ref(null)
    const { width, height } = useElementSize(el)

    return { elementsStore, el, width, height };
  },

  data() {
    return {
      editor: null,
    }
  },

  beforeMount() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          history: false,
          hardBreak: false,
        }),
        Underline,
        TextAlign.configure({
          types: ["heading", "paragraph"],
          alignments: ['left', 'center', 'right']
        }),
        TextStyle,
        FontSize,
        FontFamily,
        Color,
        Table.configure({
          resizable: true,
          lastColumnResizable: false,
          cellMinWidth: 100,
        }),
        TableRow,
        TableHeader,
        // Custom TableCell with attributes
        CustomTableCell,
        DisableKeys,
      ],
      editorProps: { 
        transformPastedHTML(html) {
          const plainText = new DOMParser().parseFromString(html, 'text/html').body.textContent;
          return plainText;
        },
        handleDOMEvents: {
          drop: (_, e) => { e.preventDefault(); },
        }
      },
      onFocus: () => {
        if (!this.elementsStore.selectedTable) {
          this.editor.commands.blur();
        }
      },
      onUpdate: ({ editor }) => {
        this.$emit('update-element', editor.getHTML());

        const isEmpty = !editor.state.doc.textContent.trim().length && !editor.getHTML().includes("table");
        if (isEmpty) {
          return this.elementsStore.deleteElement(this.index);
        }
      },
      content: this.element.editor,
      dropCursor: { width: 0, color: 'transparent' },

    })
  },
  mounted() {
    if(this.element.drop){       
        delete this.element.drop;
        this.elementsStore.selectedTable = this.editor;
        this.editor.commands.focus();
        this.editor.commands.selectAll();
    }
  },
  methods: {
    selectEditor() {
      this.elementsStore.selectedTable = this.editor;
    }
  },

  beforeUnmount() {
    this.editor.destroy()
    this.elementsStore.selectedTable = null;
  },
  watch: {
    height(newHeight) {
        if(newHeight && this.el)
          if(this.elementsStore.selectedWidget)
            this.elementsStore.selectedWidget.height = newHeight;
    },
    "element.editor"(val) {
      if (!this.elementsStore.selectedTable)
        this.editor.commands.setContent(val)
    },
    "element.active"(val) {
      if (!val) {
        this.$nextTick(() => {
          this.editor.commands.setTextSelection(0);
          this.elementsStore.selectedTable = null;
          this.editor.commands.blur();
        })
      }
    }
  }
}
</script>

<style lang="scss">
/* Table-specific styling */
.tiptap {

  u,
  u * {
    text-decoration: underline;
    border-bottom: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100% !important;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      >* {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow: hidden;
  height: auto;

}

.resize-cursor {
  cursor: ew-resize !important;
  cursor: col-resize !important;
}

.table-editor td p {
  cursor: text;
}

.table-editor button {
  border: 1px solid green !important;
  margin: 5px !important;
  padding: 5px;
}

.table-editor .ProseMirror {
  overflow: unset;
}
</style>
