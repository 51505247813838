// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.weather-widget[data-v-202b7a99] {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.weather-widget .weather-container[data-v-202b7a99] {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 5px;
}
.weather-widget .weather-container.vertical[data-v-202b7a99] {
  flex-direction: column;
}
.weather-widget .weather-icon[data-v-202b7a99] {
  display: flex;
  width: 90px;
  height: 90px;
}
.weather-widget .weather-icon img[data-v-202b7a99] {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.weather-widget .weather-info[data-v-202b7a99] {
  display: flex;
  flex-direction: column;
  gap: 0;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.weather-widget .weather-temp[data-v-202b7a99] {
  line-height: 1.1;
  text-wrap: nowrap;
}
.weather-widget .weather-temp .format[data-v-202b7a99] {
  margin-left: 5px;
  top: -0.7em;
  position: relative;
  line-height: 0;
  vertical-align: baseline;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
