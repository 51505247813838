<template>
  <link rel="stylesheet" crossorigin="anonymous"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
  <link rel="stylesheet" crossorigin="anonymous"
        href="https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css">
  <div v-if="isLoading || !ready || appStore.appLoading"
       class="w-100 bg-white min-vh-100 d-flex align-items-center justify-content-center flex-column">
    <img src="../public/loading.gif" class="loading-img" alt=""/>
    <div>
      <div class="loading-text">
        {{ $t('misc.loadingText') }}
      </div>
      <div class="loading-subtext">
        {{ $t('misc.loadingSubText') }}
      </div>
    </div>
  </div>
  <div v-else>
    <div class="main" v-if="ready">
        <div class="d-flex unselectable">
          <NavigationView v-if="!DISABLED_SIDEBAR_ROUTES.includes(currentRouteName) && currentRouteName"/>
          <div id="main-content" class="content col bg-2"
               :class="{ 'm-0': DISABLED_SIDEBAR_ROUTES.includes(currentRouteName) }">
            <router-view/>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {useAuth0} from '@auth0/auth0-vue';
import { useAppStore, usePresentStore} from "@/store";
import NavigationView from "@/components/navigation/NavigationView.vue";
import {useToast} from "vue-toastification";

export default {
  data() {
    return {
      ready: false,
      DISABLED_SIDEBAR_ROUTES: [
          '403',
          'login',
          'logout',
          'composer',
          'result',
          'preview',
          'displayView'
      ],
    }
  },
  setup() {
    const {isLoading, user, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const appStore = useAppStore();
    const presentStore = usePresentStore();

    return {appStore, presentStore, isLoading, user, isAuthenticated, getAccessTokenSilently};
  },
  beforeMount() {
    // this.appStore.hotels = hotels;
    if (!localStorage.getItem("first_login")) {
      localStorage.setItem("first_login", "false")
    }
  },
  methods: {
    async setupApp() {
      try {
        if(localStorage.getItem("first_login") === "false") {              
          if(!await this.appStore.authenticateUser(this.user) || !await this.appStore.getTenantsList())
            this.$router.replace({
              name: "logout",
          });
           // Creation of Tenant
           await this.appStore.setupTenantList()
           // Creation of User (Login Info)
           await this.appStore.setupDB(this.user)
           // Creation of Groups and tenant mapping
           const adminList = this.appStore.hotels.filter((item) => item.role === 'client-admin');
           adminList.length && await this.appStore.setupAdmins(adminList);
           // Assign Groups to hotel list
           await this.appStore.setupGroups()

           localStorage.setItem("first_login", "true")
        }

        if (this.appStore.tenant === null) {
          await this.appStore.setTenant(this.appStore.hotels[0])
          useToast().success("Welcome to Signage!");
        }

        await this.appStore.getSettings();

      } catch (error) {
        localStorage.setItem("first_login", "false")
        console.error('An error occurred:', error);
      }
    }
  },
  mounted() {
  },
  watch: {
    async isLoading(loading) {
      if (!loading) {

        if (!await this.appStore.isAlive()) {
          useToast().error("Backend API is not connected.");
          return;
        }

        if(!this.isAuthenticated || !this.user){
          localStorage.setItem("first_login", "false")
        }

        if (this.isAuthenticated && this.user) {
          try {
            await this.getAccessTokenSilently();
          } catch (error) {
            this.$router.replace({
              name: "logout",
          });
          }

          await this.appStore.fetchCityAndAirportData()
          await this.setupApp()
        }

        this.$nextTick(() => {
          this.ready = true
        })
      }
    }
  },
  components: {
    NavigationView,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
<style lang="scss">
/* Style */
.v-popper__popper{
  z-index: 100000 !important;
}

.v-popper--theme-nonius-tooltip .v-popper__inner {
  background: #ededed !important;
  color: #000000 !important;
  padding: 1px 12px !important;
  border: 1.5px solid lightgray;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.13) !important;

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.v-popper--theme-nonius-tooltip .v-popper__arrow-outer {
  border-color: lightgray !important;
}

.v-popper--theme-nonius-tooltip .v-popper__arrow-inner {
  visibility: visible;
  border-color: #ededed;
}
</style>
<style lang="scss" scoped>
html, 
body {
  min-height: 100%;
  height: 100%;
}

.content {
  margin-left: 5px;
  transition-property: margin-left;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
}

.loading-img{
  width: 100px;
  height: 100px;
}

.loading-text {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  text-align: center;
  color: #8d8d8d;
}

.loading-subtext {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.5px;
  text-align: center;
  color: #8d8d8d;
}
</style>

