// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#hand-hour[data-v-6382373a],
  #hand-minute[data-v-6382373a],
  #hand-second[data-v-6382373a]{
    transform-origin: center;
}
  `, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
