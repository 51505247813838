// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-template[data-v-661cc224] {
  padding: 40px 15px;
  text-align: center;
}
.error-actions[data-v-661cc224] {
  margin-top: 15px;
  margin-bottom: 15px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
