<template>
    <div class="rates-table" :style="{ background: currentTheme.primary, fontFamily: element.styles.fontFamily}">
        <div class="table-header" :style="{ background: currentTheme.primary, color: currentTheme.txt_secondary, fontSize: fontSizes[element.styles.fontSize]?.header }">
            <div class="table-item">             
                {{ $t('widgets.currency', this.language) }}
            </div>
            <div class="table-item">
                {{ $t('widgets.value', this.language) }}
            </div>
        </div>

        <div class="table-col"
            v-if="element.settings?.currency"
            :style="{ background: currentTheme.highlight, color: currentTheme.txt_primary, fontSize: fontSizes[element.styles.fontSize]?.text }">
            <div class="table-item currency hstack gap-3">
                <div class="currency-item" :class="element?.settings?.type !== 'default' ? 'custom' : ''">
                    <template v-if="element?.settings?.type === '1'">
                        <span class="code fw-semibold">{{element.settings.currency}}</span>
                    </template>
                    <template v-else-if="element?.settings?.type === '2'">
                        <img
                            v-if="imageSrc(element.settings.currency.toLowerCase())"
                            class="rounded flag-icon"
                            width="160px" 
                            :src="imageSrc(element.settings.currency.toLowerCase())"
                        />                    
                        <span v-else class="code fw-semibold">{{element.settings.currency}}</span>
                    </template>
                    <template v-else>
                        <img
                            v-if="imageSrc(element.settings.currency.toLowerCase())" 
                            class="rounded flag-icon ms-auto"
                            width="160px" 
                            :src="imageSrc(element.settings.currency.toLowerCase())"
                            alt=""
                        />
                        <span class="code fw-semibold">{{element.settings.currency}}</span>
                    </template>
                </div>
            </div>
            <div class="table-item value">
                <span>1.00</span>
            </div>
        </div>    

        <div class="table-col" v-for="(item, index) in getRows" :key="index"
            :style="{ background: index % 2 === 0 ? currentTheme.primary : currentTheme.secondary, color: currentTheme.txt_secondary, fontSize: fontSizes[element.styles.fontSize]?.text }">
            <div class="table-item currency hstack gap-3" v-if="item">
                <div class="currency-item" :class="element?.settings?.type !== 'default' ? 'custom' : ''">
                    <template v-if="element?.settings?.type === '1'">
                        <span class="code">{{item?.code}}</span>
                    </template>
                    <template v-else-if="element?.settings?.type === '2'">
                        <img
                            v-if="imageSrc(item?.code.toLowerCase())"
                            class="rounded flag-icon"
                            width="160px" 
                            :src="imageSrc(item?.code.toLowerCase())"
                        />                    
                        <span v-else class="code">{{item?.code}}</span>
                    </template>
                    <template v-else>
                        <img
                            v-if="imageSrc(item?.code.toLowerCase())" 
                            class="rounded flag-icon ms-auto"
                            width="160px" 
                            :src="imageSrc(item?.code.toLowerCase())"
                            alt=""
                        />
                        <span class="code">{{item?.code}}</span>
                    </template>
                </div>
            </div>
            <div class="table-item value">
                <span>{{ roundToTwo(item?.rate) }}</span>
            </div>
        </div>        

        <div class="table-footer" :style="{background: getRows.length % 2 === 0 ? currentTheme.primary : currentTheme.secondary}" v-if="Math.ceil(this.items.length / this.ratesPerPage) > 1">
            <div class="table-page" :style="{ color: currentTheme.txt_secondary }">
                {{ this.currentPage }} / {{ Math.ceil(this.items.length / this.ratesPerPage) }}  
            </div>
        </div>
    </div>

</template>

<script>
import { useOnline } from '@vueuse/core'
import { composerStore } from '@/store';

const FONT_SIZES = {
    small: {
        header: "16px",
        text: "28px"
    },
    medium: {
        header: "18px",
        text: "32px"
    },
    large: {
        header: "22px",
        text: "36px"
    }
}

export default {
    props: {
        element: {},
        styles: {},
        preview: {},
        stb: {},
        timezone: {},
        language: {
            type: String,
            default: 'EN',
            required: false
        },
    },
    setup() {
        const elementsStore = composerStore();
        const online = useOnline()

        return { elementsStore, online }
    },
    data() {
        return {
            fontSizes: FONT_SIZES,
            ratesPerPage: 1,
            currentPage: 1,
            result: [],
            items: [],
            interval: null,
            newDayInterval: null,
            dateInterval: null,
         }
    },
    async mounted() {
        this.ratesPerPage = this.checkHeight();
        await this.getRates();
    },
    beforeUnmount() {
        this.clearIntervals();  
    },
    computed: {
        imageSrc() {
            return (img) => {
                try {
                    return require(`@/assets/flags/${img}.png`)
                } catch (e) {
                    return null;
                }
            }
        },
        currentTheme() {
            const theme = this.element.theme;
            return theme;
        },
        getRows() {
            const startIndex = (this.currentPage - 1) * this.ratesPerPage;
            const endIndex = startIndex + this.ratesPerPage;

            // Get the items for the current page
            const pageItems = this.items.slice(startIndex, endIndex);

            // Only fill up the last page if it's not full
            if (pageItems.length < this.ratesPerPage) {
                const remainingSpaces = this.ratesPerPage - pageItems.length;
                return [...pageItems, ...Array(remainingSpaces).fill(null)];
            }

            // Return the items for the page without padding if full
            return pageItems;
        },
    },
    watch: {
        online(status) {
            if(status) this.getRates();
        },
        "element.height"() {
            this.currentPage = 1;
            this.ratesPerPage = this.checkHeight();           
        },
        "element.settings.currencies": {
            handler() {
                this.updateRows();
            },
            deep: true,
        },
        "element.settings.currency": {
            async handler() {
                await this.getRates();
            },
            deep: true,
        },
        "element.settings.source": {
            async handler() {
                await this.getRates();
            }
        }
    },
    methods: {
        async getRates() {
            this.items = [];
            this.elementsStore.widgetLoading = true;
            try {
                const source = this.element.settings.source === 'accor' ? 'accor' : 'general';
                this.result = await this.elementsStore.getExchangeRates(source, this.element.settings.currency);
            } catch (error) {
                this.result = {eur: {code: 'EUR', name: 'Euro', rate: 1}, usd: {code: 'USD', name: 'United States Dollar', rate: 1}};
            }
            this.elementsStore.widgetLoading = false;
            this.updateRows();
            if(!this.preview) return;
            this.runInterval();
        },
        updateRows(){
            const targetCurrencies = this.element.settings.currencies;
            const filteredRates = targetCurrencies.map(code => {

            const currency = this.result[code.toLowerCase()];
                if (currency) {
                    return {
                        code: currency.code,
                        name: currency.name,
                        rate: currency.rate,
                    };
                }
                return null; // If a country code doesn't exist in `result`, return null
            }).filter(item => item !== null); // Remove any null entries

            // sort by code
            filteredRates.sort((a, b) => {
                if (a.code < b.code) return -1;
                else if (a.code > b.code) return 1;
                else return 0;
            })
            this.items = filteredRates;
        },
        clearIntervals(){
            clearInterval(this.interval);
            clearInterval(this.dateInterval);
            clearTimeout(this.newDayInterval);
        },
        runInterval(){
            this.clearIntervals();
            this.interval = setInterval(() => {
                if(this.currentPage >= Math.ceil(this.items.length / this.ratesPerPage))
                    this.currentPage = 1;
                else
                    this.currentPage++;
            }, this.element?.settings?.transition * 1000);

            let intervalTime = 1000 * 60 * 60 * 24;
            const currentDate = this.$dayjs(this.$dayjs().tz(this.timezone).format('YYYY-MM-DD HH:mm:ss'));
            const nextHour = currentDate.add(1, 'day').startOf('day');
            const timeUntilNextHour = Math.abs(nextHour.diff(currentDate)); 
            intervalTime = timeUntilNextHour;
            this.newDayInterval = setTimeout(() => {
                this.getRates();
            }, intervalTime);            
        },
        checkHeight(){
            const containerHeight = this.element.height;
            const initialHeight = this.element.min_height - 10;
            const heightIncrement = 88;
            let countColumns = 1;

            if (containerHeight > initialHeight) {
                countColumns += Math.floor((containerHeight - initialHeight) / heightIncrement);
            }

            return countColumns;
        },
        roundToTwo(num) {
            if (typeof num !== 'number' || isNaN(num)) return ''; // Handle null value
            if (num < 0.01) return "0.00";
            return (+(Math.round(num + "e+2") + "e-2")).toFixed(2);
        }
    }
}
</script>

<style lang="scss" scoped>
.rates-table {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .table-header {
        width: 100%;
        min-height: 65px;
        max-height: 65px;
        display: flex;
        font-size: 22px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.503px;

        .table-item {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table-col {
        width: 100%;
        height: 100%;
        min-height: 48px;
        display: flex;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 37.95px;
        letter-spacing: 0.703px;

        .table-item {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            & .currency-item{
                justify-content: end;
                width: 150px;
                display: flex;
                align-items: center;
                gap: 15px;

                &.custom {
                    text-align: center;
                    justify-content: center;
                }
            }

            & .flag-icon{
                width: 48px;
                height: 34px;
            }

            & .code {
                width: 80px;
                overflow: hidden;
                font-weight: 400;
            }

            & .active {
                border-radius: 20.532px;
                padding: 10px 20px;
                border: 4px solid;

                &.smaller {
                    padding: 0px 15px;
                }
            }

            &.value {
                font-weight: 600;
            }
        }
    }

    .table-footer {
        min-height: 51px;
        max-height: 51px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;

        .table-page{
            font-size: 28px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.503px;
        }
    }
}
</style>