// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-view[data-v-5730e402] {
  padding-right: 5px;
}
.drop-button[data-v-5730e402] {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  align-items: center;
  place-content: center;
  position: relative;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
