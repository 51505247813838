<template>
  <div class="clock-analog" :style="{
    'height': this.height + 'px',
    'border-color': this.colorBorder,
    'background-color': this.colorBackground
  }">
    <div :style="{
      'color': this.fontColor,
      'font-size': this.fontSize + 32 + 'px',
    }">
    {{ this.date }}
    </div>
  </div>
</template>
  
<script>
export default {
  mounted() {
  },
  props: ["zone", "fontSize", "fontColor", "format", 'colorBorder', 'colorBackground', 'height', 'pause'],
  data() {
    return {
      now: '',
      date: '',
      timer: null,
    };
  },
  watch:{
    format(){
      this.setDateTime();
    }
  },
  methods: {
    setDateTime() {
      const now = this.$dayjs().tz(this.zone.value);     // Obtem a data/hora atual no fuso horário especificado
      if(this.format === 0)
        this.date = now.format('HH:mm');
      else
        this.date = now.format('hh:mm A');
    },
  },
  beforeMount() {
    this.setDateTime();
    if (this.pause) this.timer = setInterval(this.setDateTime, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.clock-analog {
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: 2px solid;
}
</style>