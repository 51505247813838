<template>
  <Teleport to="body">
      <div
        class="modal d-block"
        style="background: rgba(0, 0, 0, 0.3)"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" :class="{'modal-large': size == 'large', 'modal-fullscreen': size == 'fullscreen' }">
          <div class="modal-content">
            <div class="modal-body">
              <!-- Modal Content -->
              <div class="header-title">
                <slot name="header" />
              </div>
              <slot />
              <div class="footer">
                <slot name="footer" />
              </div>
            </div>
          </div>
        </div>
      </div>
  </Teleport>
</template>

<script>
export default {
  props: {
    size: {},
    type: {},
    esc: {default: true},
  },
  emits: ["close"],
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };
    return { close };
  },
  methods: {
    closeModal(evt) {
      if (evt.key === 'Escape') {
        this.$emit("close");
      }
    },
  },
  mounted(){
    if(this.esc)
      document.addEventListener('keyup', this.closeModal);
  },
  beforeUnmount(){
    document.removeEventListener('keyup', this.closeModal);
  }
};
</script>

<style lang="scss" scoped>
.modal {
  overflow-y: hidden;
  z-index: 100000;
}
.modal-dialog {
  animation: zoom-in-zoom-out 0.2s ease 1;
  place-content: center;
  min-width: 655px;
  max-width: 600px !important;
  
  &.modal-fullscreen {
    min-width: 100%;
    max-width: 100%;

    & .modal-body {
      padding: 5px;
    }
    & .modal-content{
      width: 100%;
    }
  }
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
.modal-header {
  border-bottom: 0px;
}

.modal-large{
  min-width: 1130px;
  height: 625px;
}

.modal-content{
  border-radius: 5px;
  padding: 5px;
  width: auto;
  background: #F4f4f4;
  box-shadow: 0 0 8px 2px rgba(46, 46, 46, 0.33);
}

.header-title {
  text-align: center;
  color: #484848;
  text-transform: uppercase;
}
</style>
