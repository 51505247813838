<template>
  <svg :width="this.width" :height="this.height" viewBox="0 0 178 178" fill="none" xmlns="http://www.w3.org/2000/svg">
      <!-- border -->
      <path :stroke="this.colorBorder" :fill="this.colorBackground" d="M88.643 175.287c47.852 0 86.644-38.792 86.644-86.644C175.287 40.792 136.495 2 88.643 2 40.792 2 2 40.792 2 88.643c0 47.852 38.792 86.644 86.643 86.644z" stroke-width="3.713" stroke-miterlimit="10"/>
      
      <path :stroke="this.colorBorder" :style="{transform: 'rotate(' + this.rotation.minutes + 'deg)'}" id="hand-minute" d="m89.392 27-.524 88.425" stroke-width="2.063" stroke-linecap="round" stroke-linejoin="round"/>
      <path :stroke="this.colorBorder" :style="{transform: 'rotate(' + this.rotation.seconds + 'deg)'}" id="hand-second" class="hand-second" d="M89.099 106.358 89.355 21"  stroke-width="1.238" stroke-linecap="round" stroke-linejoin="round"/>
      <path :stroke="this.colorBorder" :style="{transform: 'rotate(' + this.rotation.hours + 'deg)'}" id="hand-hour" class="hand-hour" d="m89.249 89.691.077-26.345L89.402 37"  stroke-width="2.063" stroke-linecap="round" stroke-linejoin="round"/>
      <path :fill="this.colorBorder" d="M89.106 92.77a4.126 4.126 0 1 0 0-8.253 4.126 4.126 0 0 0 0 8.252z" />
      
      <rect :fill="this.colorFont" x="88.244" y="11" width="1.512" height="6.102" rx=".349" />
      <rect :fill="this.colorFont" x="127.345" y="21.072" width="1.512" height="6.102" rx=".349" transform="rotate(30 127.345 21.072)" />
      <rect :fill="this.colorFont" x="156.172" y="49.346" width="1.512" height="6.102" rx=".349" transform="rotate(60 156.172 49.346)" />
      <rect :fill="this.colorFont" x="167" y="88.244" width="1.512" height="6.102" rx=".349" transform="rotate(90 167 88.244)" />
      <rect :fill="this.colorFont" x="156.927" y="127.346" width="1.512" height="6.102" rx=".349" transform="rotate(120 156.927 127.346)" />
      <rect :fill="this.colorFont" x="128.654" y="156.172" width="1.512" height="6.102" rx=".349" transform="rotate(150 128.654 156.172)" />
      <rect :fill="this.colorFont" x="89.756" y="167" width="1.512" height="6.102" rx=".349" transform="rotate(180 89.756 167)" />
      <rect :fill="this.colorFont" x="50.655" y="156.928" width="1.512" height="6.102" rx=".349" transform="rotate(-150 50.655 156.928)" />
      <rect :fill="this.colorFont" x="21.828" y="128.655" width="1.512" height="6.102" rx=".349" transform="rotate(-120 21.828 128.655)" />
      <rect :fill="this.colorFont" x="11" y="89.756" width="1.512" height="6.102" rx=".349" transform="rotate(-90 11 89.756)" />
      <rect :fill="this.colorFont" x="21.072" y="50.654" width="1.512" height="6.102" rx=".349" transform="rotate(-60 21.072 50.654)" />
      <rect :fill="this.colorFont" x="49.346" y="21.828" width="1.512" height="6.102" rx=".349" transform="rotate(-30 49.346 21.828)" />
  </svg>
</template>
  
<script>
export default{
    props:['height', 'width', 'colorBorder', 'colorBackground', 'colorFont', 'rotation']
}
</script>
<style scoped>
#hand-hour,
#hand-minute,
#hand-second{
  transform-origin: 90px;
  }
</style>