import { createI18n } from "vue-i18n";
import EN from "./EN.json";
import FR from "./FR.json";
import PT from "./PT.json";
import DE from "./DE.json";
import ES from "./ES.json";
import NL from "./NL.json";

if (localStorage.getItem("lang") == null) localStorage.setItem("lang", "EN");
else localStorage.getItem("lang");

const i18n = createI18n({
  locale: localStorage.getItem("lang"),
  messages: {
    EN,
    PT,
    FR,
    DE,
    ES,
    NL
  },
});

export default i18n;
