// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clock-analog[data-v-7744dc78] {
  display: flex;
  width: 100% !important;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: 2px solid;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
