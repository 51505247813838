<template>
  <div
    crossorigin="anonymous"
    v-if="!this.image64"
    id="ImageWidget"
    :style="[styles, {'background-image': `url('../imgWidget.svg')`}]"
    class="image-widget"
    :class="{flipped: element.flip}"
  ></div>
  <img
    v-else
    :key="refresh"
    crossorigin="anonymous"
    id="ImageWidget"
    :src="this.image64"
    class="uploaded-image"
    :class="{flipped: element.flip}"
    :style="[styles]"  
  />
</template>

<script>
import { composerStore } from "@/store";

export default {
  props: ["element", "index", "styles", "preview"],
  setup() {
    const elementsStore = composerStore();
    return { elementsStore };
  },
  data() {
    return {
      image64: this.element.src,
      refresh: false,
    };
  },
  watch:{
    "element.src"(val){
      this.refresh = !this.refresh;
      this.image64 = val;
    },
    image64(val){
      this.elementsStore.slideElements[this.index].src = val;
    }
  },
};
</script>

<style lang="scss" scoped>
.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-widget{
  width: 100%;
  height: 100%;
  background-size: 66px 55px;
  background-color: #f4f4f4;
  background-repeat: no-repeat;
  background-position: center;
}
.flipped {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
