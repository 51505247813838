// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Table-specific styling */
.tiptap u,
.tiptap u * {
  text-decoration: underline;
  border-bottom: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.tiptap table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100% !important;
  margin: 0;
  overflow: hidden;
}
.tiptap table td,
.tiptap table th {
  min-width: 1em;
  border: 2px solid #ced4da;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}
.tiptap table td > *,
.tiptap table th > * {
  margin-bottom: 0;
}
.tiptap table th {
  font-weight: bold;
  text-align: left;
  background-color: #f1f3f5;
}
.tiptap table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
.tiptap table .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}
.tiptap table p {
  margin: 0;
}
.tableWrapper {
  overflow: hidden;
  height: auto;
}
.resize-cursor {
  cursor: ew-resize !important;
  cursor: col-resize !important;
}
.table-editor td p {
  cursor: text;
}
.table-editor button {
  border: 1px solid green !important;
  margin: 5px !important;
  padding: 5px;
}
.table-editor .ProseMirror {
  overflow: unset;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
