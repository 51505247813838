import {defineStore} from "pinia";
import {useAppStore as appStore} from '@/store'
import i18n from "@/locales/i18n";
import APIRequest from "@/libs/cloud_auth";

export const usePresentStore = defineStore("present", {
    state: () => ({
        isLoading: false,
        group_folders: [
            {
                id: true,
                name: i18n.global.t('presentation.shared'),
            },
            {
                id: false,
                name: i18n.global.t('presentation.drafts'),
            },
        ],
        local_folders: [
            {
                id: 'published',
                name: i18n.global.t('players.published'),
            },
            {
                id: 'backup',
                name: i18n.global.t('presentation.drafts'),
            },
        ],

    }),
    getters: {
        getFolders(state) {
            return JSON.parse(JSON.stringify(state.local_folders));
        },
        getGroupFolders(state) {
            return JSON.parse(JSON.stringify(state.group_folders));
        },
    },
    actions: {
        async getLinkedTemplateTenant(template){
            return new Promise((resolve, reject) => {
                APIRequest(`properties/linked-template-tenant/?template=${template}`)
                    .then((response) => {
                        resolve(response.data.results);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async getLinkedTemplates(){
            return new Promise((resolve, reject) => {
                APIRequest(`properties/linked-template-tenant/getLinkedTemplateTenants/`, 'post', {
                    "ncm": appStore().getCurrentTenant
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async updateLinkedTemplateTenant(template, tenants){
            return new Promise((resolve, reject) => {
                APIRequest(`properties/linked-template-tenant/updateLinkedTemplates/`, 'post',
                {
                    "tenants": tenants,
                    "template": template
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async getPresentationsAPI() {
            return new Promise((resolve, reject) => {
                APIRequest(`compose/presentation/list_local_presentations/${appStore().getCurrentTenant}`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async getGroupPresentationsAPI() {
            return new Promise((resolve, reject) => {
                APIRequest(`compose/presentation/list_group_presentations/${appStore().getTenantGroup}`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async createPresentationAPI(formData) {
            return new Promise((resolve, reject) => {
                APIRequest(`compose/presentation/`, 'post', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async editPresentationAPI(id, formData) {
            return new Promise((resolve, reject) => {
                APIRequest(`compose/presentation/${id}/`, 'patch', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async deletePresentationAPI(id) {
            // this.presentations_demo = this.presentations_demo.filter(item => !ids.includes(item.id))
            return new Promise((resolve, reject) => {
                APIRequest(`compose/presentation/${id}/`, 'delete')
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async duplicatePresentation(presentation_id){
            return new Promise((resolve, reject) => {
              const url = `compose/presentation/duplicate_presentation/`;
              APIRequest(url, 'post' , {id: presentation_id})
                .then(response => {
                  // Resolve the Promise with the response data
                  resolve(response.data);
                })
                .catch(error => {
                  // Reject the Promise with the error
                  reject(error);
                });
              });
          },
        renamePresentationAPI(id, name) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('name', name);

                APIRequest(`compose/presentation/${id}/`, 'post', formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        deletePresentations(ids) {
            this.presentations_demo = this.presentations_demo.filter(item => !ids.includes(item.id))
        },
        findPresentationRefType(id) {
            return this.getAllPresentations.find(item => item.id === id).source_ref_type
        },
        setThumbnail(id, thumbnail) {
            const presentation = this.presentations_demo.find(presentation => presentation.id === id);
            if (presentation) {
                presentation.thumbnail = thumbnail;
            }
        },
    },
});
