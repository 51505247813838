// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/svg/Icon_search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-header[data-v-a52e003c] {
  width: auto;
  height: 35px;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: relative;
  cursor: pointer;
}
.drop-header:hover .drop-title[data-v-a52e003c] {
  background-color: #ededed;
}
.drop-header.rotate .drop-options[data-v-a52e003c] {
  flex-direction: column-reverse;
}
.drop-header.active[data-v-a52e003c] {
  box-shadow: 0 1.5px 5.8px 0 rgba(0, 0, 0, 0.17);
  z-index: 1000;
}
.drop-header.active .drop-title[data-v-a52e003c] {
  background-color: #ededed;
  border-color: white !important;
}
.drop-header.active .drop-item[data-v-a52e003c] {
  font-weight: 600;
}
.drop-header .drop-title[data-v-a52e003c] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
  border-radius: 5px;
  border: solid 2px #dbdbdb;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  height: 100%;
  gap: 10px;
}
.drop-header .drop-title span.placeholder-text[data-v-a52e003c] {
  opacity: 0.25;
}
.drop-header .drop-title span[data-v-a52e003c] {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.drop-header .drop-options[data-v-a52e003c] {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1.5px 5.8px 0 rgba(0, 0, 0, 0.17);
  z-index: 100;
  position: absolute;
  left: 0;
  max-height: 250px;
  background: #fff;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
}
.drop-header .drop-options.inversed[data-v-a52e003c] {
  left: unset;
  right: 0;
}
.drop-header .drop-options .drop-list[data-v-a52e003c] {
  min-height: 35px;
  max-height: 200px;
  overflow-y: auto;
}
.drop-header .drop-options .drop-list .no-results[data-v-a52e003c] {
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 14px;
  color: #5f5f5f;
  font-weight: normal;
}
.drop-header .drop-options .drop-list .drop-item[data-v-a52e003c] {
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  color: #5f5f5f;
  font-weight: normal;
  overflow-x: hidden;
  cursor: pointer;
}
.drop-header .drop-options .drop-list .drop-item.active[data-v-a52e003c] {
  font-weight: 600;
}
.drop-header .drop-options .drop-list .drop-item .checkbox[data-v-a52e003c] {
  width: 20px;
  min-width: 20px;
  display: flex;
}
.drop-header .drop-options .drop-list .drop-item span[data-v-a52e003c] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.drop-header .drop-options .drop-list .drop-item[data-v-a52e003c]:hover {
  background-color: #f4f4f4;
}
.drop-header .select-input[data-v-a52e003c] {
  border: 2px solid #ededed;
  margin: 2px;
  border-radius: 5px;
  height: 38px;
}
.drop-header input[data-v-a52e003c] {
  width: 100%;
  height: 35px;
  outline: none;
  border: 2px solid transparent;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat scroll 7px 6px;
  padding-left: 30px;
}
.v-enter-active[data-v-a52e003c],
.v-leave-active[data-v-a52e003c] {
  transition: opacity 0.2s ease-in-out;
}
.v-enter-from[data-v-a52e003c],
.v-leave-to[data-v-a52e003c] {
  opacity: 0;
}
[data-v-a52e003c] .drop-header .drop-title .up-down {
  transition: transform 0.2s ease-in-out;
}
[data-v-a52e003c] .drop-header.active .drop-title .up-down {
  transform: rotate(180deg);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
