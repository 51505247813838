import dayjs from "dayjs";

import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import weekday from 'dayjs/plugin/weekday'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import timezones from './timezones.json'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(calendar)
dayjs.extend(weekday)
dayjs.extend(customParseFormat)

// dayjs.tz.guess()

import 'dayjs/locale/en' 
import 'dayjs/locale/pt' 
import 'dayjs/locale/de' 
// load on demand
dayjs.locale(localStorage.getItem("lang"));


if(!localStorage.getItem('timezones'))
    localStorage.setItem('timezones', JSON.stringify(timezones))
