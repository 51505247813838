<template>
  <div class="header-view hstack ps-1 pt-2 pb-1 gap-2 mx-1">
    <slot />

    <TenantComponent :tenants="tenants" />
   
  </div>
</template>
<script>
import TenantComponent from "@/components/TenantComponent";

export default {
  props: {
    tenants: {
      required: false,
      default: true,
    }
  },
  data() {
    return {
    }
  },
  components: { TenantComponent },

  methods: {

  },
}
</script>
<style lang="scss" scoped>
.header-view {
  padding-right: 5px;
}
.drop-button {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  align-items: center;
  place-content: center;
  position: relative;
}

</style>
