<!-- eslint-disable no-unused-vars -->
<template>
  <div class="editor-component" @click="singleClick" @dblclick="doubleClick" :class="{'selected':  isEditable}">
      <editor-content autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" ref="el" class="text-editor" readonly :editor="editor" v-show="isEditable"/>
      <div class="text-box" v-html="value" v-show="!isEditable"></div>
  </div>
</template>

<script>
import { useElementSize } from '@vueuse/core'
import { ref } from 'vue'

import { composerStore } from "@/store";
import { EditorContent } from "@tiptap/vue-3";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from '@tiptap/extension-font-family'
import { Color } from "@tiptap/extension-color";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import FontSize from "tiptap-extension-font-size";
import Mention from '@tiptap/extension-mention'

import { Editor } from "@tiptap/vue-3";
import { storeToRefs } from "pinia";
import { debounce } from 'lodash';

import { Extension } from "@tiptap/core";
import suggestion from './suggestion.js'

const DisableKeys = Extension.create({
  addKeyboardShortcuts() {
    return {
      'Mod-Alt-1': () => true,
      'Mod-Alt-2': () => true,
      'Mod-Alt-3': () => true,
      'Mod-Alt-4': () => true,
      'Mod-Alt-5': () => true,
      'Mod-Alt-6': () => true,
    };
  },
});

export default {
  props: ["element", "value"],
  emits: ["update:value"],
  components: { EditorContent, },
  setup() {
      const elementsStore = composerStore();
      const { onEditor, mounted } = storeToRefs(elementsStore);
      
      const el = ref(null)
      const { width, height } = useElementSize(el)

      return { elementsStore, onEditor, mounted, el, width, height };
  },
  data() {
      return {
          editor: null,
          isEditable: false,
      };
  },
  methods:{
      singleClick(){
          this.elementsStore.selectedEditor = this.editor
          if(!this.isEditable)
                this.elementsStore.selectedEditor.commands.selectAll()
      },
      doubleClick(){
          if(!this.isEditable)
              this.elementsStore.selectedEditor.commands.selectAll()

          this.elementsStore.selectedEditor.setEditable(true);
          this.elementsStore.selectedEditor.commands.focus()
      },
      
  },
  mounted() {
      this.debouncedAddToHistory = debounce(() => {
        this.elementsStore.addToHistory();
      }, 200);

      this.editor = new Editor({
          editable: !!this.element.active,
          onUpdate: ({ editor }) => {
              this.isEditable = editor.isEditable
              this.elementsStore.onEditor = this.isEditable
              this.$emit('update:value', editor.getHTML().replace(/<p[^>]*><\/p>/g, "<p>&nbsp;</p>"));
          },
          onFocus: () => {
              this.elementsStore.selectedEditor = this.editor
          },
          editorProps: { 
            transformPastedHTML(html) {
              const plainText = new DOMParser().parseFromString(html, 'text/html').body.textContent;
              return plainText;
            },
            handleDOMEvents: {
              drop: (_, e) => { e.preventDefault(); },
          } },
          // hide the drop position indicator
          dropCursor: { width: 0, color: 'transparent' },
          content: this.value,
          enablePasteRules: true,
          parseOptions: {
              preserveWhitespace: 'full'
          },
          extensions: [
              StarterKit.configure({
                history: false,
                hardBreak: false, 
                codeBlock: false
              }),
              Underline,
              TextAlign.configure({
                  types: ["heading", "paragraph"],
                  alignments: ['left', 'center', 'right']
              }),
              TextStyle,
              FontSize,
              FontFamily,
              Color,
              this.elementsStore.presentationDetails.template.type === 'conference_room' ?
              Mention.configure({
                HTMLAttributes: {
                  class: 'mention',
                },
                suggestion,
              }) : '',
              DisableKeys,
            ]
      });

      if(this.elementsStore.mounted){
          if(this.element.active){
              this.isEditable = true;
              this.editor.commands.focus();
              this.editor.commands.selectAll();
          }
          this.elementsStore.selectedEditor = this.editor;
      }
      
      if(this.element.drop){       
        delete this.element.drop;
        this.elementsStore.selectedEditor = this.editor;
        this.isEditable = true;
        this.editor.commands.focus();
        this.editor.commands.selectAll();
      }
      
  },
  beforeUnmount() {
    if (this.editor) {
          this.isEditable = false;
          this.editor.destroy()
    }
  },
  watch: {
      height(newHeight) {
        if(newHeight && this.el)
          if(this.elementsStore.selectedWidget)
            this.elementsStore.selectedWidget.height = newHeight;
      },
      "element.active"(val){
          if(!val){
              this.isEditable = val
              this.elementsStore.onEditor = val
          }
      },
      "element.editor"(val){
        if (this.editor && !this.isEditable) {
          this.editor.commands.setContent(val, true);
        }
      },
      onEditor(val){
          if(!val){
              this.elementsStore.selectedEditor.commands.selectAll();
              this.isEditable = val
          }
      },
      isEditable(){
          this.elementsStore.onEditor = this.isEditable
      },
      value(){
        if(this.element.active) {
          if(!this.isEditable)
            return this.elementsStore.addToHistory();
          this.debouncedAddToHistory();
        }
      }
  }
}
</script>
<style lang="scss">

.ProseMirror {
height: 100% !important;
overflow: hidden;
outline: none !important;
font-size: 32px;
}

.ProseMirror * {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ProseMirror p {
  margin: 0;
}


.text-editor {
height: 100% !important;
padding-inline: 10px;
cursor: text;
}
:deep(.text-editor u) {
  text-decoration: none !important;
}
:deep(.text-editor u *) {
  text-decoration: underline;
}
::selection {
background: rgba(133, 133, 133, 0.315) !important;
}
</style>

<style lang="scss" scoped>
:deep(.tiptap u){
  text-decoration: none !important;

}
.editor-component{
  min-height: 100%;
}
.text-box {
    height: 100% !important;
    overflow: hidden;
    outline: none !important;
    font-size: 32px;
    padding-inline: 10px;
    word-wrap: break-word;
    white-space: break-spaces;
}

</style>
