<template>
  <div>
    <div v-if="element.type == 'Classic'">
      <ClockClassic :width="element.width" :height="element.height" :colorBorder="element.styles['borderColor']"
        :colorBackground="element.styles.backgroundColor1" :colorFont="this.element.styles.fontColor"
        :zone="this.element.settings.timezone" :rotation="rotation" />
    </div>
    <div v-if="element.type == 'Square'">
      <ClockSquare :width="element.width" :height="element.height" :colorBorder="element.styles.borderColor"
        :colorBackground="element.styles.backgroundColor1" :colorFont="this.element.styles.fontColor"
        :zone="this.element.settings.timezone" :rotation="rotation" />
    </div>
    <div v-if="element.type == 'Minimal'">
      <ClockMinimal :width="element.width" :height="element.height" :colorBorder="element.styles['borderColor']"
        :colorBackground="element.styles.backgroundColor1" :colorFont="this.element.styles.fontColor" :zone="this.element.settings.timezone" :rotation="rotation" />
    </div>
    <div
      style="width: 100%!important; height: 100%!important; justify-content: center!important; align-items: center!important; align-self: center!important; display: flex;"
      v-if="element.type == 'Digital'">
      <ClockAnalog :fontColor="this.element.styles.fontColor" :format="this.element.format"
        :fontSize="(this.element.height * this.element.width) / 3000" :colorBorder="element.styles['borderColor']"
        :colorBackground="element.styles.backgroundColor1" :height="this.element.height"
        :zone="this.element.settings.timezone" :pause="this.pauser" />
    </div>
  </div>
</template>
<script>
import { composerStore } from "@/store";
import ClockClassic from "./clock-types/ClockClassic.vue";
import ClockAnalog from "./clock-types/ClockAnalog.vue";
import ClockSquare from "./clock-types/ClockSquare.vue";
import ClockMinimal from "./clock-types/ClockMinimal.vue";
import dayjs from 'dayjs';

export default {
  props: ["element", "index", "styles", "pauser"],
  name: "App",
  setup() {
    const elementsStore = composerStore();
    return { elementsStore };
  },
  data() {
    return {
      dateTime: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      rotation: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      type: "AM",
      timer: undefined,
      pause: this.pauser
    };
  },
  computed: {
    dataStatus() {
      return this.dateTime;
    },
    typeStatus() {
      return this.element.type
    }
  },
  watch: {
    dataStatus() {
      this.dataStatus && this.fRotate()
    },
    typeStatus() {
      this.typeStatus && this.element.type === 'Analog' ? this.pause = false : this.pause = true
    }
  },
  methods: {
    setDateTime() {
      let now = dayjs().tz(this.element.settings.timezone.value); 
      this.dateTime = {
        hours: now.format('HH'),            
        minutes: now.format('mm'),
        seconds: now.format('ss')
      };
    },
    fRotate() {
      if (this.pause == true) {
        if(this.element.type === 'Digital') return;
          this.rotation.seconds = this.dateTime.seconds * 6;
          this.rotation.minutes = this.dateTime.minutes * 6;
          this.rotation.hours = this.dateTime.hours * 30;
      }
    },
  },
  beforeMount() {
    if (this.pause) {
      this.timer = setInterval(this.setDateTime, 1000);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  components: { ClockClassic, ClockAnalog, ClockSquare, ClockMinimal }
};
</script>
<style lang="scss" scoped>
*,
*:before,
*:after {
  box-sizing: border-box;
}

.clock {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  border: 6px solid rgb(66, 66, 66);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2),
    inset 0 3px 0 rgba(255, 255, 255, 0.5), inset 0 -4px 0 rgba(0, 0, 0, 0.1),
    0 2px 5px rgba(0, 0, 0, 0.2);
  /* background: linear-gradient(top, #f0f1e9, #f0f1e9 100%);*/
}

.numbers,
clockwise {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.numbers ul {
  list-style: none;
}

.numbers ul li {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  line-height: 1.5em;
  font-size: 1.3em;
  color: #a5a595;
}

.numbers ul li:first-child {
  top: 0;
  left: 50%;
  margin-left: -0.75em;
}

.numbers ul li:nth-child(2) {
  top: 50%;
  right: 0;
  margin-top: -0.75em;
}

.numbers ul li:nth-child(3) {
  left: 50%;
  bottom: 0;
  margin-left: -0.75em;
}

.numbers ul li:nth-child(4) {
  bottom: 10%;
  align-self: center;
  margin-left: -30px;
  color: #5c5c5c;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
}

.numbers ul li:last-child {
  top: 50%;
  left: 0;
  margin-top: -0.75em;
}

.clockwise .center,
.support {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3c3c3c;
  background-position: 50% -12px;
}

.clockwise .center {
  left: 50%;
  margin-left: -5px;
  top: 50%;
  margin-top: -5px;
  z-index: 5;
}

.support {
  margin: auto;
  top: -260px;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4), 0 -1px 2px rgba(0, 0, 0, 0.3);
}

.support::before {
  content: "";
  width: 4px;
  height: 100px;
  background: #fff;
  position: absolute;
  z-index: -1;
  transform: rotate(38deg);
  transform-origin: 50% 0%;
  top: 5px;
  left: 3px;
  background: linear-gradient(left, #f3f3f3, #fff);
  box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.2), inset 1px 0 4px rgba(0, 0, 0, 0.2);
}

.support::after {
  content: "";
  width: 4px;
  height: 100px;
  background: #fff;
  position: absolute;
  z-index: -1;
  transform: rotate(-38deg);
  transform-origin: 50% 0%;
  top: 5px;
  left: 1px;
  background: linear-gradient(left, #f3f3f3, #fff);
  box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.2),
    inset 1px 0 4px rgba(0, 0, 0, 0.2);
}

[class*="hand-"] {
  border-radius: 4px;
  box-shadow: 0 0 0.5em rgba(black, 0.2);
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: 4;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}

.hand-second {
  width: 2px;
  top: 18px;
  height: 78px;
  background: #3c3c3c;
  transform-origin: 50% 100%;
  transform: rotate(30deg);
}

.hand-minute {
  width: 3px;
  top: 23px;
  margin-left: -3px;
  height: 88px;
  background: #3c3c3c;
  transform-origin: 50% 80%;
  transform: rotate(60deg);
}

.hand-hour {
  width: 5px;
  top: 53px;
  transform-origin: 50% 40px;
  margin-left: -3px;
  height: 50px;
  background: #3c3c3c;
}
</style>