// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-2b653061],[data-v-2b653061]:before,[data-v-2b653061]:after {
  box-sizing: border-box;
}
.clock[data-v-2b653061] {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  border: 6px solid rgb(66, 66, 66);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), inset 0 3px 0 rgba(255, 255, 255, 0.5), inset 0 -4px 0 rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.2);
  /* background: linear-gradient(top, #f0f1e9, #f0f1e9 100%);*/
}
.numbers[data-v-2b653061],
clockwise[data-v-2b653061] {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.numbers ul[data-v-2b653061] {
  list-style: none;
}
.numbers ul li[data-v-2b653061] {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  line-height: 1.5em;
  font-size: 1.3em;
  color: #a5a595;
}
.numbers ul li[data-v-2b653061]:first-child {
  top: 0;
  left: 50%;
  margin-left: -0.75em;
}
.numbers ul li[data-v-2b653061]:nth-child(2) {
  top: 50%;
  right: 0;
  margin-top: -0.75em;
}
.numbers ul li[data-v-2b653061]:nth-child(3) {
  left: 50%;
  bottom: 0;
  margin-left: -0.75em;
}
.numbers ul li[data-v-2b653061]:nth-child(4) {
  bottom: 10%;
  align-self: center;
  margin-left: -30px;
  color: #5c5c5c;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
}
.numbers ul li[data-v-2b653061]:last-child {
  top: 50%;
  left: 0;
  margin-top: -0.75em;
}
.clockwise .center[data-v-2b653061],
.support[data-v-2b653061] {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3c3c3c;
  background-position: 50% -12px;
}
.clockwise .center[data-v-2b653061] {
  left: 50%;
  margin-left: -5px;
  top: 50%;
  margin-top: -5px;
  z-index: 5;
}
.support[data-v-2b653061] {
  margin: auto;
  top: -260px;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4), 0 -1px 2px rgba(0, 0, 0, 0.3);
}
.support[data-v-2b653061]::before {
  content: "";
  width: 4px;
  height: 100px;
  background: #fff;
  position: absolute;
  z-index: -1;
  transform: rotate(38deg);
  transform-origin: 50% 0%;
  top: 5px;
  left: 3px;
  background: linear-gradient(left, #f3f3f3, #fff);
  box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.2), inset 1px 0 4px rgba(0, 0, 0, 0.2);
}
.support[data-v-2b653061]::after {
  content: "";
  width: 4px;
  height: 100px;
  background: #fff;
  position: absolute;
  z-index: -1;
  transform: rotate(-38deg);
  transform-origin: 50% 0%;
  top: 5px;
  left: 1px;
  background: linear-gradient(left, #f3f3f3, #fff);
  box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.2), inset 1px 0 4px rgba(0, 0, 0, 0.2);
}
[class*=hand-][data-v-2b653061] {
  border-radius: 4px;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.2);
  left: 50%;
  position: absolute;
  top: 50%;
  z-index: 4;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}
.hand-second[data-v-2b653061] {
  width: 2px;
  top: 18px;
  height: 78px;
  background: #3c3c3c;
  transform-origin: 50% 100%;
  transform: rotate(30deg);
}
.hand-minute[data-v-2b653061] {
  width: 3px;
  top: 23px;
  margin-left: -3px;
  height: 88px;
  background: #3c3c3c;
  transform-origin: 50% 80%;
  transform: rotate(60deg);
}
.hand-hour[data-v-2b653061] {
  width: 5px;
  top: 53px;
  transform-origin: 50% 40px;
  margin-left: -3px;
  height: 50px;
  background: #3c3c3c;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
