<template>
  <div class="sidebar col-auto col-md-3 col-xl-2 px-0" :class="{'expanded': this.appStore.sidebar}">
    <div
      class="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100 text-center"
    >
      <a
        href="/"
        style="height: 51px;"
        class="d-flex align-items-center pb-3 mb-md-0 mt-2 mx-auto text-white text-decoration-none"
      >
        <span class="logo-span fs-5" :class="{'expanded': this.appStore.sidebar}">
          <img class="logo" src="@/assets/svg/logo.svg"  alt=""/>
          <div class="logo-text" v-show="this.appStore.sidebar">Nonius Signage</div>
        </span>
      </a>
      <Teleport to="body">
        <div class="rounded-circle mb-2 collapse-bar align-items-center justify-content-center" @click="ToggleMenu" :class="{'expanded': this.appStore.sidebar}">
          <svg v-if="this.appStore.sidebar" width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#th4ci6p6aa)">
                  <circle cx="15.5" cy="14.5" r="11.5" fill="#fff"/>
              </g>
              <path d="M21.078 11H9.78M21.078 14.992h-5.34M21.078 18.54h-5.34" stroke="#3C3C3C" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M11.763 14.77 9.75 16.783l2.013 2.012" stroke="#3C3C3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <defs>
                  <filter id="th4ci6p6aa" x=".714" y=".536" width="29.571" height="29.571" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                      <feOffset dy=".821"/>
                      <feGaussianBlur stdDeviation="1.643"/>
                      <feComposite in2="hardAlpha" operator="out"/>
                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2309_118899"/>
                      <feBlend in="SourceGraphic" in2="effect1_dropShadow_2309_118899" result="shape"/>
                  </filter>
              </defs>
          </svg>

          <svg v-else width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#z9175yz72a)">
                <circle cx="15.5" cy="14.5" r="11.5" fill="#fff"/>
            </g>
            <path d="M9.75 18.795h11.297M9.75 14.803h5.34M9.75 11.255h5.34" stroke="#3C3C3C" stroke-width="1.5" stroke-linecap="round"/>
            <path d="m19.065 15.025 2.013-2.013L19.065 11" stroke="#3C3C3C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
                <filter id="z9175yz72a" x=".714" y=".536" width="29.571" height="29.571" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy=".821"/>
                    <feGaussianBlur stdDeviation="1.643"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_829_12385"/>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_829_12385" result="shape"/>
                </filter>
            </defs>
        </svg>

        </div>
      </Teleport>
      <ul
        class="nav nav-pills flex-column mb-sm-auto mb-0 mt-4 align-items-center align-items-sm-start align-self-start pe-none"
        id="menu"
      >
      
        <li v-for="(item, index) in menu" :key="item.link" class="pe-auto">
<!--          v-if="hideOrNot(item.group)"-->
          <SidebarButton
              v-if="showButton(item)"
              :title="item.title"
              :icon="item.icon"
              :link="item.link"
              :index="index"
              :submenu='item.submenu'
          />          
        </li>
      
      </ul>

      <div v-if="this.appStore.sidebar" class="policies">
          <span>© {{ $dayjs().year() }} Nonius</span>
          <br />
          <span>Version {{ version }}</span>
          <br />
          <span class="blue clickable" @click="goToPolicy()">Privacy Policy, Info/Support</span>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarButton from "./SidebarButton.vue";
import {useAppStore} from "@/store";

export default {
  setup(){
    const appStore = useAppStore();
    return { appStore };
  },
  data(){
    return{
      version: process.env.VUE_APP_VERSION,
      tenant: this.appStore.tenant,
      menu: !this.appStore.getTenantGroup ?
            this.appStore.menu.filter((item) => item.link !== 'templates')
          : this.appStore.menu
    }
  },
  components: {
    SidebarButton,
  },
  methods: {
    goToPolicy(){
      // open page on new tab
      window.open('https://noniussolutions.com/gdpr/', '_blank')
    },
    ToggleMenu() {
      this.appStore.toggleSidebar()
    },
  },
  mounted(){
  },
  computed:{
    showButton(){
      return (item) =>{
        if(!this.tenant) return false
        return item.roles.includes(this.tenant.role)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo{
  width:32px;
}
.logo-span.expanded{
  width:auto;
}
.logo-span.expanded{
  display: flex;
  gap: 5px;
  align-items: center;
  height: 35px;
}
.logo-text {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.5px;
  width: 100px;

}
.sidebar {
  background-color: #3c3c3c;
  width: 44px;
  z-index: 100000;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;

  &.expanded{
    width: 183px;

  }
}

.collapse-bar.expanded{
  left: 164px;
}
.collapse-bar{
  width: 31px;
  height: 31px;
  position: fixed;
  z-index: 100000;
  top: 60px;
  left: 22px;
  display: flex;
  filter: drop-shadow(0 1px 4px rgb(0 0 0/15%));
  transition-property: left;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
  cursor: pointer;
}
.policies {
    color: #b5b5b5;
    font-size: 12px;
    width: 100%;
    padding: 15px 6px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 0.5px;
    overflow: hidden;
    white-space: nowrap;
    & .blue {
      color: #4195ff;
    }
  }
</style>
