import {defineStore} from "pinia";
import {useAppStore as appStore} from "@/store";
import APIRequest from "@/libs/cloud_auth";

export const useSettingsStore = defineStore("settings", {
    state: () => ({
        isLoading: true,
        error: '',
        items: [],
        count: 0,
        totalPages: 1,
        next: null,
        previous: null,
        controller: new AbortController()
    }),
    getters: {
        getItems() {
            return this.items;
        },
        isResultEmpty(){
            if(this.isLoading) return false;
            return this.items.length === 0;
        }
    },
    actions: {
        calculateTotalPages(totalItems, itemsPerPage) {
            const totalPages = Math.ceil(totalItems / itemsPerPage);
            return totalPages < 1 ? 1 : totalPages;
        },
        async changeSettings(id, field, value){
            const data = {
                [field]: value
            };

            await APIRequest(
                `properties/settings/${id}/`,
                'patch',
                data
            );
        },
        async getSettings(search, page, pageSize, filter, sort) {
                if (this.isLoading) {
                    this.controller.abort();
                }

                this.controller = new AbortController();
                this.isLoading = true;

                await APIRequest(
                    `properties/settings/getAvailableTenants/?page=${page}&page_size=${pageSize}`,'post',{
                        tenant_list: !filter.length ? appStore().getListedHotels : filter,
                        search: search,
                        ordering: sort ? sort : 'id',
                    },
                    {
                        signal: this.controller.signal
                    }
                )
                    .then((response) => {
                            this.count = response.data["count"];
                            this.next = response.data["next"];
                            this.previous = response.data["previous"];
                            this.items = response.data["results"];
                            this.totalPages = this.calculateTotalPages(this.count, pageSize)
                            this.error = '';
                            this.isLoading = false;

                        }
                    ).catch((error) => {
                        if (error.name !== 'CanceledError') {
                            this.error = error;
                            console.error(error);
                            this.isLoading = false;
                        }
                    });

        }
    },
});
