<template>
  <div></div>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: "LoginView",
  components: {},
  setup() {
    const { loginWithRedirect, error, isLoading } = useAuth0();
    return { loginWithRedirect, isLoading, error };
  },

  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  mounted(){
    if(!this.error) {
      this.login()
    }
    else {
      this.$router.replace({
        name: "logout",
      });
    }
  },
  methods: {
     login() {
       localStorage.removeItem("tenant");
       this.loginWithRedirect({
         appState: {
           target: "/",
         },
       });
    },
  },
};
</script>

<style lang="scss" scoped>
body{
  background-color: #f0f0f3 !important;
}
.Login {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
}
h6 {
  text-align: center;
  color: #a8a8a8;
}

h5 {
  text-align: center;
  font-weight: bold;
  color: #e3e3e3;
}
label{
  color: #e3e3e3;
}

input{
  background: #2d2d2d;
  color: white;
  border:0;
}
button, button:hover{
  background: #F4f4f4;
}
.container {
  width: 450px;
  box-shadow: 0px 0px 2px 1px rgba(50, 50, 50, 0.2);
  padding: 25px;
  height:366px;
  border-radius: 10px;
}
.container input {
  margin-bottom: 20px;
}
.container button {
  margin-bottom: 10px;
}
</style>
