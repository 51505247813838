import {defineStore} from "pinia";
import {useAppStore as appStore} from '@/store'

import axios from "axios";
import APIRequest from "@/libs/cloud_auth";

export const useWayfinderStore = defineStore("wayfinder", {
  state: () => ({
    isLoading: true,
    isEventLoading: false,

    wayfinder: [],
    conferences: [],
  }),
  getters: {
    getWayfinder(){
      return this.wayfinder;
    },
    getConferences(){
      return this.conferences;
    },
  },
  
  actions: {
    async wayfinderDestinations(wayfinder_id, hours){
      try{
        let params;
        if(hours){
          params = {
            params: { 
              "time": hours 
            }
          }
        }
        
        const response = await axios.get(`guidance/get_destination_events/${wayfinder_id}`, params)
        return response.data
      } catch ( error ){
        console.log(error)
      }
    },
    async wayfinderLocations(){
      try{
        this.isLoading = true;
        const response = await APIRequest(`gadgets/location/?tenant=${appStore().getCurrentTenant}&type=wayfinder`)
        this.wayfinder = response.data["results"]
      } catch ( error ){
        console.log(error)
      } finally {
        this.isLoading = false;
      }
    },
    async conferencesLocations(){
      try{
        const response = await APIRequest(`gadgets/location/?tenant=${appStore().getCurrentTenant}&type=conference`)
        this.conferences = response.data["results"]
      } catch ( error ){
        console.log(error)
      }
    },
    async wayFinder(source_location){
      try{
        this.isEventLoading = true;
        const response = await APIRequest(`guidance/wayfinder-option/?source_location__id=${source_location}`)
        const result = response.data["results"]
        return result.map(item => {
          return {...item, name: this.conferences.find(conf => conf.id === item.destination_location)?.name};
        })

      } catch ( error ){
        console.log(error)
      } finally {
        this.isEventLoading = false;
      }
    },
    async deleteWayFinder(id){
      try{
          const response = await APIRequest(`/guidance/wayfinder-option/${id}/`, 'delete')
          return response.data
        }
      catch(error){
        console.log(error)
      }
    },
    async addWayFinder(form){
      return new Promise((resolve, reject) => {
        const urlLocation = `guidance/wayfinder-option/`
        APIRequest(urlLocation, 'post', { 
             source_location: form.source_location,
             destination_location: form.destination_location,
             wayfinder_icon: null,
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });

    },
    async editWayFinderIcon(form){
      try{
        return new Promise((resolve, reject) => {
          const url = `/guidance/wayfinder-option/${form.id}/`
          APIRequest(url, 'patch', {
          wayfinder_icon: form.wayfinder_icon
         }).then((response)=>{
          resolve(response.data)
         })
         .catch((error)=>{
          reject(error)
         })
        })
      }
      catch(error){
        console.log(error)
      }
    }

  },
});
