<template>
  <div class="hstack gap-2 ms-auto">
    <ModalView v-if="listModal" @close="listModal = false">
      <div class="dialog-label">{{ $t('menu.language') }}</div>

      <div class="tenant-frame my-2">
        <div class="tenant-list">
          <div v-for="item in computedLanguages" :key="item.code" class="tenant-item clickable"
            @click="lang = item.code" :class="{ active: lang === item.code }">
            {{ item.name }}&nbsp;({{ item.code }})
          </div>
          <div class="ms-1" v-if="!computedLanguages.length">{{ $t('misc.noResults') }}</div>
        </div>
      </div>

      <div class="hstack gap-2 mt-4 mb-2">
        <button class="theme-button-sm-icon" @click="listModal = false" type="reset">
          <Icon name="cancel" />
          {{ $t("button.cancel") }}
        </button>
        <button class="theme-button-sm-icon btn-bg-conf align-self-auto" @click="changeLang(lang)">
          <Icon name="save" />
          {{ $t("button.save") }}
        </button>
      </div>
    </ModalView>
    <ModalView v-if="impersonatorModal" @close="impersonatorModal = false">
      <CloudUsers @close-modal="impersonatorModal = false" />
    </ModalView>

      <div class="impersonator-menu unselectable" v-if="session?.user_impersonation">
        <OnClickOutside @trigger="viewUserCollapsed = true">
        <div class="i-dropdown-menu" :class="{ active: !viewUserCollapsed }" @click="viewUserCollapsed = !viewUserCollapsed">          
          <Icon name="impersonating" />
          <span>{{ $t('button.impersonating') }}...</span>
          <Icon name='arrow_down_white' :class="!viewUserCollapsed ? 'rotated' : ''" />

          <div class="i-dropdown-content" :class="{ inverted: !tenants }" v-if="!viewUserCollapsed" @click.stop>
            <span>{{$t('misc.viewPageAs')}}: </span>
            <span>{{ session?.impersonation?.name }}</span>
            <span>{{ session?.impersonation?.email }}</span>
            <button class="theme-button-sm-icon mt-auto" @click="impersonateLogout()" type="button">
              <Icon name="arrow_left" />
              {{ $t("button.endImpersonation") }}
            </button>

          </div>

        </div>
      </OnClickOutside>
    </div>

    <div class="user-dropdown" v-if="tenants">
      <div class="user-select">
        <HotelList :readonly="this.$route.name === 'templates'" :disabled="disabled" v-model="selected"
          :options='getRoleList()' />
        <template>
          <svg class="ms-2" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.6 15.63H2.4c-.247 0-.45-.245-.45-.54 0-.296.203-.54.45-.54h13.2c.245 0 .45.244.45.54 0 .295-.205.54-.45.54z"
              fill="#4195FF" />
            <path
              d="M13.68 15.455c-.295 0-.54-.204-.54-.45V12.33c0-.246.245-.45.54-.45.295 0 .54.204.54.45v2.676c0 .246-.238.45-.54.45z"
              fill="#4195FF" />
            <path
              d="M13.678 12.78a1.77 1.77 0 0 1-1.77-1.77V9.648a1.77 1.77 0 1 1 3.54 0v1.362a1.77 1.77 0 0 1-1.77 1.77zm0-3.996a.87.87 0 0 0-.87.87v1.362a.87.87 0 0 0 1.74 0V9.654a.87.87 0 0 0-.87-.87zM10.38 15.45a.453.453 0 0 1-.45-.45V5.418c0-.954-.402-1.362-1.338-1.362H4.854c-.942 0-1.35.408-1.35 1.362V15c0 .246-.204.45-.45.45a.453.453 0 0 1-.45-.45V5.418c0-1.458.798-2.262 2.25-2.262h3.738c1.446 0 2.238.804 2.238 2.262V15c0 .246-.204.45-.45.45z"
              fill="#4195FF" />
            <path
              d="M8.249 7.38h-2.97c-.246 0-.45-.245-.45-.54 0-.295.204-.54.45-.54h2.97c.246 0 .45.245.45.54 0 .295-.204.54-.45.54zM8.249 9.63h-2.97c-.246 0-.45-.245-.45-.54 0-.295.204-.54.45-.54h2.97c.246 0 .45.245.45.54 0 .295-.204.54-.45.54zM6.84 15.45c-.295 0-.54-.205-.54-.45v-2.25c0-.247.245-.45.54-.45.295 0 .54.203.54.45V15c0 .245-.245.45-.54.45z"
              fill="#4195FF" />
          </svg>

          <div class="user-name-container">
            <span class="user-name">{{ appStore.getHotelName(selected) }}</span>
          </div>
        </template>
      </div>


    </div>
    <OnClickOutside @trigger="this.openDrop = false">
      <div class="dropdown drop-circle">
        <button type="button" class="p-0 drop-button avatar-button" :class="{ active: openDrop }"
          @click.prevent="openDrop = !openDrop">
          <template v-if="session.user_impersonation">{{ session.impersonation.name[0].toUpperCase() }}</template>
          <img v-else :alt="user.name[0].toUpperCase()" :src="user.picture" referrerpolicy="no-referrer"
            :style="{ width: '100%', height: '100%', borderRadius: '50%' }" />
        </button>

        <div class="dropdown-container" ref="dropdownContainer" v-if="openDrop">
          <div class="arrow-up"></div>
          <div class="user-role">
            <span>{{ session.user_impersonation ? session.impersonation.name : user.name }}</span>
            <span>{{ session.user_impersonation ? session.impersonation.email : user.email }}</span>
            <span class="role-badge smaller text-uppercase" :class="appStore.getCurrentRole.toLowerCase().replace('client-', '')">{{ $t(`roles.${appStore.getCurrentRole}`) }}</span>
          </div>
          <div class="divider-horizontal" />
          <div class="dropdown-list" ref="dropdownList">
            <div class="drop-item" @click="redirectUser()"
              v-if="appStore.getCurrentRole === 'nonius-admin' || appStore.getCurrentRole === 'client-admin'">
              <Icon name="user_management" />
                {{ $t('dropdown.userManagement') }}
            </div>
            <div class="drop-item" @click="this.openDrop = false, impersonatorModal = true" v-if="appStore.getCurrentRole.startsWith('nonius-')">
              <Icon name="impersonator" />
              {{ $t('dropdown.impersonation') }}
            </div>
            <div class="drop-item" @click="listModal = true, this.openDrop = false">
              <Icon name="lang" />
              {{ $t('menu.language') }}
              <span class="ms-auto">{{ lang }}</span>
            </div>
            <div class="divider-horizontal" />
            <div class="drop-item" @click="signOut">
              <Icon name="logout" />
              {{ $t("menu.logout") }}
            </div>
          </div>
        </div>
      </div>
    </OnClickOutside>
  </div>
</template>
<script>
import { useAuth0 } from '@auth0/auth0-vue';
import { OnClickOutside } from "@vueuse/components";
import HotelList from "@/components/HotelList";
import { useAppStore } from "@/store";
import ModalView from "@/components/helpers/ModalView.vue";
import CloudUsers from './CloudUsers.vue';

export default {
  props: {
    tenants: {
      required: false,
      default: true,
    },
    disabled: [Boolean]
  },
  setup() {
    const appStore = useAppStore();
    const { user } = useAuth0();

    return { appStore, user };
  },
  components: { OnClickOutside, HotelList, ModalView, CloudUsers },
  data() {
    return {
      numRows: 4,
      openDrop: false,
      listModal: false,
      impersonatorModal: false,
      lang: localStorage.getItem("lang"),
      selected: this.appStore.getCurrentTenant,

      session: this.appStore.session,
      viewUserCollapsed: true,
    };
  },
  async mounted() {
    if (this.appStore.getCurrentRole === 'nonius-admin' || this.appStore.getCurrentRole === 'client-admin') {
      this.numRows = 5;
    }

  },
  methods: {
    redirectUser() {
      window.location.href = process.env.VUE_APP_USER_MANAGEMENT_URL +
        '?action=management' +
        '&client_id=' +
        process.env.VUE_APP_USER_MANAGEMENT_CLIENT_ID; // Redirect to external site
    },
    getRoleList() {
      return this.appStore.hotels
    },
    signOut() {
      this.$router.replace({
        name: "logout",
      });
    },
    changeLang(value) {
      localStorage.setItem("lang", value);
      this.$dayjs.locale(value)
      window.location.reload();
    },
    async impersonateLogout() {
      if (!await this.appStore.impersonateLogout(this.user.email)){
        this.$router.replace({
          name: "logout",
        });
      } else {
        window.location.reload();
      }
    },

  },
  watch: {
    async selected(val) {
      this.appStore.appLoading = true;
      await this.appStore.setTenantSelect(val);
      window.location.reload()
    },
    listModal(val) {
      if (!val) {
        this.lang = localStorage.getItem("lang");
      }
    },
  },
  computed: {
    getHeight() {
      return (35 * this.numRows) + "px";
    },
    computedLanguages() {
      return this.appStore.appLanguages;
    }
  }
};
</script>
<style lang="scss" scoped>
.tenant-list {
  display: flex;
  gap: 5px;
  flex-direction: column;

  & .tenant-item {
    background-color: #f4f4f4;
    padding: 7px 8px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: #3c3c3c;

    &.active {
      background-color: #3c3c3c;
      color: #fff;
    }
  }
}

.tenant-frame {
  background: #ffffff;
  width: 390px;
  height: 408px;
  padding: 12px 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.drop-button.avatar-button {
  transition: 0.2s all ease-in-out;
  border-radius: 100%;
  font-size: 22px;
  font-weight: 600;
  color: white;
  outline: 2px solid white;
  background-color: #484848;
  width: 30px;
  height: 30px;
  display: flex;
  place-content: center;
  align-items: center;

  &:hover,
  &.active {
    outline-color: #4195ff;
  }
}

.drop-circle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #dbdbdb;
  //margin-right: 0.35rem;
}

.user-dropdown {
  width: 223px;
  height: 40px;
  background-color: #ffffff;
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
}

.user-select {
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-name-container {
  height: 30px;
  overflow: hidden;
  width: 150px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484848;

  &::after {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  &.tenant-list {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.user-name {
  display: inline-block;
  display: -webkit-inline-box;
  max-height: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.dropdown-container {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 1000001;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 5px;
  width: 202px;
  height: auto;

  & .arrow-up {
    filter: drop-shadow(1px -5px 2px rgb(0 0 0 / 0.1));
  }
}

.user-role {
  padding: 15px 15px 10px;
  display: flex;
  flex-direction: column;

  span:nth-child(1) {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    color: #484848;
  }

  span:nth-child(2) {
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 5px;
    color: #8d8d8d;
  }

}

.dropdown-list {
  width: 202px;
  text-align: left;
  height: auto;
  margin-bottom: 5px;

  & .drop-item {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 5px;
    padding-inline: 15px;
    height: 35px;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-align: left;
    color: #5f5f5f;

    svg {
      min-width: 20px;
      max-width: 20px;
    }

    &:hover {
      background: #f4f4f4;
    }
  }
}

.arrow-up {
  width: 0;
  height: 0;
  position: absolute;
  right: 12px;
  top: -10px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid white;
}
.impersonator-menu {
  z-index: 1000000;

  .i-dropdown-menu {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 5px;
    background: #FF7070;
    min-width: 100%;
    padding-inline: 10px;
    height: 40px;
    gap: 7px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;

    &.active, &:hover {
      background: #FF4747;
    }

    svg{
      width: 25px;
      transition: transform 0.3s ease-in-out;
    }

    span {
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    svg:last-child {
      margin-left: auto;
    }
  }

  .i-dropdown-content {
    position: absolute;
    top: 45px;
    left: 0;
    width: 290px;
    height: 141px;
    display: flex;
    padding: 15px;
    flex-direction: column;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.12);

    &.inverted{
      left: unset;
      right: 0;
    }

    span:nth-child(1) {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #8D8D8D;
    }

    span:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #484848;
    }

    span:nth-child(3) {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #484848;
    }
  }
}

.rotated{
  transform: rotate(180deg);
}
</style>
