<template>
  <div>
    <HeaderView> </HeaderView>
    <div class="no-perms m-2 bg-1">
      <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="57" height="57" rx="5" fill="#EDEDED"/>
        <path d="M22.502 26.4949V22.038C22.502 18.7267 24.7341 15.6519 28.0011 15.068C32.2733 14.3067 35.9837 17.5662 35.9837 21.698V26.4949" stroke="#B5B5B5" stroke-width="2.34304" stroke-linecap="round"/>
        <path d="M37.4992 27.4404H20.8688C19.6441 27.4404 18.6514 28.4332 18.6514 29.6578V40.8261C18.6514 42.0507 19.6441 43.0435 20.8688 43.0435H37.4992C38.7238 43.0435 39.7166 42.0507 39.7166 40.8261V29.6578C39.7166 28.4332 38.7238 27.4404 37.4992 27.4404Z" fill="#B5B5B5" stroke="#B5B5B5" stroke-width="2.32826" stroke-linecap="round"/>
      </svg>
      <span class="perm-text mt-2">You currently don’t have permission to access this page.</span>
      <span class="perm-text-secondary">To access, please change tenant or contact the administrator.</span>
    </div>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView.vue";
import {useAppStore} from "@/store";
import {useAuth0} from "@auth0/auth0-vue";

export default {
  components: {HeaderView},
  setup() {
    const appStore = useAppStore()
    const { isAuthenticated } = useAuth0();

    return { appStore, isAuthenticated }
  },
  mounted(){
    if(!this.isAuthenticated) {
      return this.$router.replace({
        name: 'login',
      });
    }

    if(this.appStore.getFirstRoute() !== '403')
      this.$router.replace({
        name: this.appStore.getFirstRoute(),
      });
  }
}
</script>

<style lang="scss" scoped>
.no-perms{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
}

i {
  color: #545454;
}

.perm-text {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.5px;
  text-align: center;
  color: #8d8d8d;
}

.perm-text-secondary {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.5px;
  text-align: left;
  color: #8d8d8d;
}
</style>