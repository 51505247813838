// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/svg/Icon_search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-name-container[data-v-6df5491c] {
  height: 30px;
  overflow: hidden;
  width: 150px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484848;
}
.user-name-container[data-v-6df5491c]::after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.user-name-container.tenant-list[data-v-6df5491c] {
  margin-top: 5px;
  margin-bottom: 5px;
}
.arrow-dropdown[data-v-6df5491c] {
  transition: transform 0.2s ease-in-out;
}
.user-name[data-v-6df5491c] {
  display: inline-block;
  display: -webkit-inline-box;
  max-height: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.select-field[data-v-6df5491c] {
  width: 223px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px 0 10px;
  border-radius: 5px;
  border: solid 2px #ededed;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: 0.5px;
  text-align: left;
  position: relative;
}
.select-box[data-v-6df5491c] {
  z-index: 1000001;
  height: auto;
  box-shadow: 0 1.5px 5.8px 0 rgba(0, 0, 0, 0.17);
  width: 312px;
  border-radius: 5px;
  margin-top: 1px;
  top: 40px;
  right: -2px;
}
.select-field.active[data-v-6df5491c] {
  background: #ededed;
  border-color: white;
  box-shadow: 0 1.5px 5.8px 0 rgba(0, 0, 0, 0.17);
}
.select-input[data-v-6df5491c] {
  margin: 1.7px;
}
.select-svg[data-v-6df5491c] {
  display: flex;
  align-items: center;
}
.select-name[data-v-6df5491c] {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #5f5f5f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-wrap: nowrap;
  width: auto;
}
.select-name.active[data-v-6df5491c] {
  font-weight: 600;
}
.options[data-v-6df5491c] {
  color: #9a9a9a;
}
.select-items[data-v-6df5491c] {
  width: 312px;
  max-height: 210px;
  overflow-y: auto;
}
.select-items.more-items[data-v-6df5491c] {
  max-height: 420px !important;
}
.no-results[data-v-6df5491c] {
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 14px;
  color: #5f5f5f;
  font-weight: normal;
}
.select-item[data-v-6df5491c] {
  padding: 4.5px 10px;
  overflow: hidden;
}
.select-item[data-v-6df5491c]:hover {
  background-color: #f4f4f4;
}
.text-more[data-v-6df5491c] {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4195ff;
  margin: 5px 13px;
  cursor: pointer;
}
.select-actions[data-v-6df5491c] {
  border-top: 2px solid #ededed;
}
.select-buttons[data-v-6df5491c] {
  display: flex;
  justify-content: flex-end;
  padding: 8.5px 15px;
  gap: 15px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  color: #8d8d8d;
}
.select-buttons button[data-v-6df5491c]:nth-child(2) {
  font-weight: 600;
  color: #4195ff;
}
.search-bar[data-v-6df5491c] {
  width: 100%;
  border: 2px solid #ededed;
  padding-right: 3px;
  height: 36px;
  background-color: white;
  border-radius: 5px;
}
.search-bar[data-v-6df5491c]:focus {
  background-color: white;
}
input[data-v-6df5491c] {
  outline: none;
  border: 2px solid transparent;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat scroll 7px 7px;
  padding-left: 30px;
}
.v-enter-active[data-v-6df5491c],
.v-leave-active[data-v-6df5491c] {
  transition: opacity 0.2s ease;
}
.v-enter-from[data-v-6df5491c],
.v-leave-to[data-v-6df5491c] {
  opacity: 0;
}
.rotated[data-v-6df5491c] {
  transform: rotate(180deg);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
