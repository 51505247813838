// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rates-table[data-v-19d0c1a6] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rates-table .table-header[data-v-19d0c1a6] {
  width: 100%;
  min-height: 65px;
  max-height: 65px;
  display: flex;
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.503px;
}
.rates-table .table-header .table-item[data-v-19d0c1a6] {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rates-table .table-col[data-v-19d0c1a6] {
  width: 100%;
  height: 100%;
  min-height: 48px;
  display: flex;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 37.95px;
  letter-spacing: 0.703px;
}
.rates-table .table-col .table-item[data-v-19d0c1a6] {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rates-table .table-col .table-item .currency-item[data-v-19d0c1a6] {
  justify-content: end;
  width: 150px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.rates-table .table-col .table-item .currency-item.custom[data-v-19d0c1a6] {
  text-align: center;
  justify-content: center;
}
.rates-table .table-col .table-item .flag-icon[data-v-19d0c1a6] {
  width: 48px;
  height: 34px;
}
.rates-table .table-col .table-item .code[data-v-19d0c1a6] {
  width: 75px;
}
.rates-table .table-col .table-item .active[data-v-19d0c1a6] {
  border-radius: 20.532px;
  padding: 10px 20px;
  border: 4px solid;
}
.rates-table .table-col .table-item .active.smaller[data-v-19d0c1a6] {
  padding: 0px 15px;
}
.rates-table .table-col .table-item.value[data-v-19d0c1a6] {
  font-weight: 600;
}
.rates-table .table-footer[data-v-19d0c1a6] {
  min-height: 51px;
  max-height: 51px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}
.rates-table .table-footer .table-page[data-v-19d0c1a6] {
  font-size: 32.852px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.503px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
