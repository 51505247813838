<template lang="">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="lsdnazml2a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="3" width="30" height="30">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 3.6C10.046 3.6 3.6 10.046 3.6 18c0 7.953 6.446 14.4 14.4 14.4 7.952 0 14.4-6.447 14.4-14.4 0-7.954-6.448-14.4-14.4-14.4z" fill="#fff"/>
        </mask>
        <g mask="url(#lsdnazml2a)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6 18c0 7.953 6.447 14.4 14.4 14.4 7.952 0 14.4-6.447 14.4-14.4 0-7.954-6.448-14.4-14.4-14.4-7.953 0-14.4 6.446-14.4 14.4z" fill="#fff"/>
        </g>
        <path d="m11.6 17 4.846 5 7.754-8" stroke="#76C834" stroke-width="3.7" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped></style>