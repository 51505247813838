// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body[data-v-5c6101e4] {
  background-color: #f0f0f3 !important;
}
.Login[data-v-5c6101e4] {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
}
h6[data-v-5c6101e4] {
  text-align: center;
  color: #a8a8a8;
}
h5[data-v-5c6101e4] {
  text-align: center;
  font-weight: bold;
  color: #e3e3e3;
}
label[data-v-5c6101e4] {
  color: #e3e3e3;
}
input[data-v-5c6101e4] {
  background: #2d2d2d;
  color: white;
  border: 0;
}
button[data-v-5c6101e4], button[data-v-5c6101e4]:hover {
  background: #F4f4f4;
}
.container[data-v-5c6101e4] {
  width: 450px;
  box-shadow: 0px 0px 2px 1px rgba(50, 50, 50, 0.2);
  padding: 25px;
  height: 366px;
  border-radius: 10px;
}
.container input[data-v-5c6101e4] {
  margin-bottom: 20px;
}
.container button[data-v-5c6101e4] {
  margin-bottom: 10px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
