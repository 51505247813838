// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tenant-list[data-v-60dccbf3] {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.tenant-list .tenant-item[data-v-60dccbf3] {
  background-color: #f4f4f4;
  padding: 7px 8px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #3c3c3c;
}
.tenant-list .tenant-item.active[data-v-60dccbf3] {
  background-color: #3c3c3c;
  color: #fff;
}
.tenant-frame[data-v-60dccbf3] {
  background: #ffffff;
  width: 390px;
  height: 408px;
  padding: 12px 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.drop-button.avatar-button[data-v-60dccbf3] {
  transition: 0.2s all ease-in-out;
  border-radius: 100%;
  font-size: 22px;
  font-weight: 600;
  color: white;
  outline: 2px solid white;
  background-color: #484848;
  width: 30px;
  height: 30px;
  display: flex;
  place-content: center;
  align-items: center;
}
.drop-button.avatar-button[data-v-60dccbf3]:hover, .drop-button.avatar-button.active[data-v-60dccbf3] {
  outline-color: #4195ff;
}
.drop-circle[data-v-60dccbf3] {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #dbdbdb;
}
.user-dropdown[data-v-60dccbf3] {
  width: 223px;
  height: 40px;
  background-color: #ffffff;
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
}
.user-select[data-v-60dccbf3] {
  display: flex;
  align-items: center;
  gap: 5px;
}
.user-name-container[data-v-60dccbf3] {
  height: 30px;
  overflow: hidden;
  width: 150px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: 0.5px;
  text-align: left;
  color: #484848;
}
.user-name-container[data-v-60dccbf3]::after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.user-name-container.tenant-list[data-v-60dccbf3] {
  margin-top: 5px;
  margin-bottom: 5px;
}
.user-name[data-v-60dccbf3] {
  display: inline-block;
  display: -webkit-inline-box;
  max-height: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.dropdown-container[data-v-60dccbf3] {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 10000;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 5px;
  width: 202px;
  height: auto;
}
.dropdown-container .arrow-up[data-v-60dccbf3] {
  filter: drop-shadow(1px -5px 2px rgba(0, 0, 0, 0.1));
}
.user-role[data-v-60dccbf3] {
  padding: 15px 15px 10px;
  display: flex;
  flex-direction: column;
}
.user-role span[data-v-60dccbf3]:nth-child(1) {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: #484848;
}
.user-role span[data-v-60dccbf3]:nth-child(2) {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 5px;
  color: #8d8d8d;
}
.user-role span[data-v-60dccbf3]:nth-child(3) {
  padding: 0 8px;
  border-radius: 5px;
  background-color: #5f5f5f;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-align: left;
  max-width: max-content;
  color: #fff;
}
.dropdown-list[data-v-60dccbf3] {
  width: 202px;
  text-align: left;
  height: auto;
  margin-bottom: 5px;
}
.dropdown-list .drop-item[data-v-60dccbf3] {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  padding-inline: 15px;
  height: 35px;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: 0.5px;
  text-align: left;
  color: #5f5f5f;
}
.dropdown-list .drop-item[data-v-60dccbf3]:hover {
  background: #f4f4f4;
}
.arrow-up[data-v-60dccbf3] {
  width: 0;
  height: 0;
  position: absolute;
  right: 12px;
  top: -10px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid white;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
