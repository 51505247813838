// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe[data-v-3cae0dbc] {
  pointer-events: none;
}
.widget[data-v-3cae0dbc] {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  display: flex;
}
.widget svg[data-v-3cae0dbc] {
  margin: auto;
}
.widget-wrapper[data-v-3cae0dbc] {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
}
.widget-wrapper[data-v-3cae0dbc] > * {
  width: 100%;
  height: 100%;
}
.widget-wrapper iframe.loading[data-v-3cae0dbc] {
  width: 0%;
  height: 0%;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
