// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.islamic-table[data-v-cf985a8e] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.islamic-table .table-header[data-v-cf985a8e] {
  width: 100%;
  min-height: 88px;
  max-height: 88px;
  display: flex;
}
.islamic-table .table-header .table-item[data-v-cf985a8e] {
  height: 100%;
  font-size: 28px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.503px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.islamic-table .table-col[data-v-cf985a8e] {
  width: 100%;
  height: 100%;
  display: flex;
}
.islamic-table .table-col .table-item[data-v-cf985a8e] {
  height: 100%;
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.503px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.islamic-table .table-col .table-item.prayer-item[data-v-cf985a8e] {
  justify-content: start;
  padding-left: 60px;
}
.islamic-table .table-col .table-item .active[data-v-cf985a8e] {
  border-radius: 20.532px;
  padding: 10px 20px;
  border: 4px solid;
}
.islamic-table .table-col .table-item .active.smaller[data-v-cf985a8e] {
  padding: 0px 15px;
}
.islamic-table .table-footer[data-v-cf985a8e] {
  min-height: 51px;
  max-height: 51px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.islamic-table .table-footer .table-page[data-v-cf985a8e] {
  font-size: 32.852px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.503px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
